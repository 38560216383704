import { addDays, format, isSameDay, isWeekend } from "date-fns"
import Holidays, { HolidaysTypes } from "date-holidays"

export const getCanadianNationwideHolidays = (year: number) => {
  const hd = new Holidays("CA") // 'CA' is the country code for Canada
  const allHolidays = hd.getHolidays(year)

  const nationwideHolidays = allHolidays.filter((holiday) => {
    return holiday.type === "public" // type 'public' means it's a nationwide holiday
  })

  return nationwideHolidays
}

export const calculateDeliveryDate = (
  deliveryDays: number,
  currentDate: Date = new Date()
) => {
  if (!deliveryDays) {
    return ""
  }

  const year = currentDate.getFullYear()
  const nationwideHolidays = getCanadianNationwideHolidays(year).map(
    (holiday) => new Date(holiday.date)
  )

  let deliveryDate = new Date(currentDate) // Start with the current date
  let daysAdded = 0

  // Add delivery days, skipping weekends and holidays
  while (daysAdded < deliveryDays) {
    deliveryDate = addDays(deliveryDate, 1) // Move to the next day

    // Check if the delivery date is a weekend or a holiday
    const isHoliday = nationwideHolidays.some((holiday) =>
      isSameDay(holiday, deliveryDate)
    )

    if (!isWeekend(deliveryDate) && !isHoliday) {
      daysAdded++
    }
  }

  // Make sure the delivery date is not a weekend or a holiday
  while (
    isWeekend(deliveryDate) ||
    nationwideHolidays.some((holiday) => isSameDay(holiday, deliveryDate))
  ) {
    deliveryDate = addDays(deliveryDate, 1)
  }

  return format(deliveryDate, "MM/dd/yyyy")
}
