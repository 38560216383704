import "./ViewerModal.scss"
import { Modal, Model, Loading, Button } from "@/components"
import { ModelResponse } from "@/services/apiDigifabster/model"
import { Collapse } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import DFMCheck from "../DFMCheck/DFMCheck"
import { IViewerModalData } from "@/interfaces/order.interface"
import { EModelAnalysisStatus, IModelAnalysis } from "@/store/product"
import { useEffect, useMemo, useState } from "react"
import {
  BlockIcon,
  OrderReviewIcon,
  ResizeButtonIcon,
  SuccessDFMIcon,
  WarningDFMIcon
} from "@/assets"
import { Configformattedjson } from "@/services/apiDigifabster/quote"
import { ButtonHierarchy, Type } from "@/enums/common.enum"

const dfmAnalysis: IViewerModalData[] = [
  {
    status: EModelAnalysisStatus.SUCCESS,
    isDisabled: false,
    title: "Part Size",
    value: "Part fits the build volume of the selected technology.",
    id: 0
  },
  {
    status: EModelAnalysisStatus.WARNING,
    isDisabled: false,
    title: "Thin Walls",
    value: "Walls are too thin, minimum recommended thickness value is 10.0 mm",
    id: 1
  },
  {
    status: EModelAnalysisStatus.LOADING,
    isDisabled: true,
    title: "Hole Size",
    value: "Minimum hole diameter is at least 1.0 mm",
    id: 3
  },
  {
    status: EModelAnalysisStatus.LOADING,
    isDisabled: true,
    title: "Minimum Detail Size",
    value: "Minimum detail size is 0.5 mm.",
    id: 2
  },
  {
    status: EModelAnalysisStatus.LOADING,
    isDisabled: true,
    title: "Trapped Volume",
    value: "The part cannot contain any trapped volumes.",
    id: 4
  }
]

export const tagStatusClass = {
  [EModelAnalysisStatus.LOADING]: {
    text: "Checking for Manufacturing Issues....",
    icon: OrderReviewIcon,
    className: "viewer-modal-analysis-heading-title-loading"
  },
  [EModelAnalysisStatus.SUCCESS]: {
    text: "Ready To Order",
    icon: SuccessDFMIcon,
    className: ""
  },
  [EModelAnalysisStatus.WARNING]: {
    text: "Manufacturing Issues Detected",
    icon: WarningDFMIcon,
    className: "viewer-modal-analysis-heading-title-warning"
  },
  [EModelAnalysisStatus.ERROR]: {
    text: "Manufacturing Issues Detected",
    icon: BlockIcon,
    className: "viewer-modal-analysis-heading-title-error"
  }
}

enum Technology {
  SLS = "Selective Laser Sintering (SLS)",
  MJF = "Multi-Jet Fusion (MJF)",
  SLA = "Stereolithography (SLA)",
  FDM = "Fused Deposition Modeling (FDM)",
  DMLS = "Direct Metal Laser Sintering (DMLS)"
}

const techThicknessMap: { [key in Technology]: string } = {
  [Technology.SLS]: "0.8",
  [Technology.MJF]: "1",
  [Technology.SLA]: "0.8",
  [Technology.FDM]: "1",
  [Technology.DMLS]: "0.8"
}

interface ViewerModalProps {
  modelTitle: string
  isModalOpen: boolean
  onCancel: Function
  currentPart?: ModelResponse
  loading?: boolean
  analysis?: IModelAnalysis
  technology?: Configformattedjson[]
}

function ViewerModal({
  modelTitle,
  isModalOpen,
  onCancel,
  currentPart,
  loading,
  analysis,
  technology
}: ViewerModalProps) {
  const { title, size, volume, file_model_viewer_url, units } =
    currentPart || {}
  const [dfmAnalysisData, setDfmAnalysisData] =
    useState<IViewerModalData[]>(dfmAnalysis)

  const tech = useMemo(
    () => technology?.find((item) => item.key === "Technology"),
    [technology]
  )

  useEffect(() => {
    if (!analysis) return

    let _dfmAnalysis = [...dfmAnalysis]
    _dfmAnalysis[1] = {
      status: analysis.status,
      isDisabled: false,
      title: "Thin Walls",
      value: analysis.result?.wallThickness
        ? `Walls are at least ${
            analysis?.result?.minimal_wall_thickness ||
            techThicknessMap[tech?.value as Technology] ||
            "0"
          } mm.`
        : `Walls are too thin, minimum recommended thickness value is ${
            analysis?.result?.minimal_wall_thickness ||
            techThicknessMap[tech?.value as Technology] ||
            "0"
          } mm.`,
      id: 1
    }
    setDfmAnalysisData([..._dfmAnalysis])
  }, [analysis])

  return (
    <Modal
      open={isModalOpen}
      title="Manufacturing Analysis"
      onCancel={() => onCancel()}
      footer={null}
      width={1307}
    >
      <div className="viewer-modal-wrapper">
        <div className="viewer-modal">
          <div className="viewer-modal-infor">
            <div className="">
              <div className="viewer-modal-infor-content">
                <h3>{modelTitle}</h3>
                {size && (
                  <>
                    <p>
                      {size?.x.toFixed(2) || 0.0} x {size?.y.toFixed(2) || 0.0}{" "}
                      x {size?.z.toFixed(2) || 0.0} {units}
                    </p>
                    <p>
                      {volume?.toFixed(2) || 0.0} {units}3
                    </p>
                  </>
                )}
                <Button
                  customType={Type.NEUTRAL}
                  hierarchy={ButtonHierarchy.LINK}
                  customClassName="btn-resize"
                >
                  <img src={ResizeButtonIcon} alt="" />
                  Resize Model
                </Button>
              </div>

              <div className="viewer-modal-analysis">
                <p>Pre-Manufacturing Check</p>
                <DFMCheck data={dfmAnalysisData}></DFMCheck>
              </div>
            </div>
          </div>
          <div className="viewer-modal-model">
            {loading ? (
              <div className="viewer-modal-model-loading">
                <Loading />
              </div>
            ) : (
              <Model
                src={file_model_viewer_url || ""}
                wallThicknessFileUrl={analysis?.result?.thin_faces_file_url}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ViewerModal
