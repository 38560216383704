import { Button } from "@/components"
import { Size } from "@/enums/common.enum"
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js"
import { useNavigate, useParams } from "react-router-dom"
import "./CheckOutForm.scss"
import { useEffect, useState } from "react"
import { useAppLoading } from "@/hooks/useLoading"
import { useLazyGetQuoteDetailQuery } from "@/services/apiDigifabster/quote"
import { getInvoiceHash } from "@/utils/stringHelper"

interface CheckOutFormProps {
  invoiceId?: number
  invoiceHash?: string
}

const CheckOutForm = ({ invoiceId, invoiceHash }: CheckOutFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const [getOrderDetail] = useLazyGetQuoteDetailQuery()
  const [stripeError, setStripeError] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { orderId } = useParams()

  const handleSubmit = async () => {
    setIsLoading(true)
    if (!stripe || !elements) return

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required"
    })

    if (!error) {
      setStripeError(false)
    }
  }

  const getInvoice = async () => {
    const { data } = await getOrderDetail({
      quoteId: orderId || ""
    })
    const checkNullThumb = data?.main_invoice === null
    const isPaid = data?.main_invoice?.is_paid

    if (checkNullThumb || !isPaid) {
      return new Promise((res, rej) => {
        setTimeout(() => {
          getInvoice().then(res).catch(rej)
        }, 1000)
      })
    }
    const invoiceId = data?.main_invoice?.id
    const invoiceHash = getInvoiceHash(data?.main_invoice?.pay_url)
    if (!stripeError && invoiceId && invoiceHash && isPaid) {
      setIsLoading(false)
      return navigate(`/orders/invoices/${invoiceId}/${invoiceHash}`, {
        replace: true
      })
    }
  }

  useEffect(() => {
    if (isLoading) {
      getInvoice()
    }
  }, [stripeError, invoiceId, invoiceHash, isLoading])

  useAppLoading([isLoading])

  return (
    <>
      <p className="alternative">Pay with card</p>

      <div>
        <LinkAuthenticationElement></LinkAuthenticationElement>
      </div>

      <p className="title">Card information</p>
      <PaymentElement></PaymentElement>

      <div className="btn-container">
        <Button
          onClick={handleSubmit}
          customClassName="btn-pay"
          customSize={Size.LARGE}
        >
          Pay
        </Button>
      </div>

      <div className="footer">
        <div className="left">
          <p>
            Powered by{" "}
            <a href="https://stripe.com/" target="_blank">
              Stripe
            </a>
          </p>
        </div>
        <div className="right">
          <a href="https://stripe.com/legal/consumer" target="_blank">
            Terms
          </a>
          <a href="https://stripe.com/privacy" target="_blank">
            Privacy
          </a>
        </div>
      </div>
    </>
  )
}

export default CheckOutForm
