export const isEmptyObject = (obj?: object) => {
  if (!obj) return false

  return Object.keys(obj).length === 0
}

export const calculatePerPartCost = (
  pricePerPart: string | number,
  product_count: number | undefined,
  startup_cost: number | undefined,
  amount?: number
) => {
  const productCount = Number(product_count) || 0
  const startupCost = Number(startup_cost) || 0

  if (productCount === 0) {
    return 0
  }

  let result = 0

  if (amount) {
    result =
      ((Number(pricePerPart) * productCount + startupCost) / productCount) *
      amount
  } else {
    result = (Number(pricePerPart) * productCount + startupCost) / productCount
  }

  return result.toFixed(2)
}
