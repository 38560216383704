import { api } from "../apiInstance"

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getShippingRate: build.mutation<
      ShippingRateResponse[],
      ShippingRatePayload
    >({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.orderId}/shipping/rates/`,
        method: "POST",
        body: queryArg.arg
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as shippingService }

export interface ShippingRatePayload {
  orderId: number
  arg: {
    country: string
    state: string
    postalCode: string
    city: string
  }
}

export interface SaveShippingDataPayload {
  arg: {
    country: string
    state: string
    postalCode: string
    city: string
    addressLine1: string
    addressLine2: string
  }
  orderId: number
}

export interface SaveShippingMethodPayload {
  arg: {
    method: string
    carrier?: string
    note?: string
  }
  orderId: number
}

export interface ShippingPricePayload {
  arg: {
    price: number
  }
  orderId: number
}

export interface ShippingRateResponse {
  name: string
  code: string
  service: string
  price: number
}

export const { useGetShippingRateMutation } = injectedRtkApi
