import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import { ButtonHierarchy } from "@/enums/common.enum"
import "./ProductItem.scss"
import { Button, NumberField } from "@/components"
import {
  BlockIcon,
  OrderReviewIcon,
  SuccessToastIcon,
  WarningDFMIcon
} from "@/assets"
import { DeleteOutlined } from "@ant-design/icons"
import { EModelAnalysisStatus } from "@/store/product"
import { UploadFile } from "antd"

export const buttonStatusClass = {
  [EModelAnalysisStatus.LOADING]: "button-loading",
  [EModelAnalysisStatus.SUCCESS]: "button-success",
  [EModelAnalysisStatus.WARNING]: "button-warning",
  [EModelAnalysisStatus.ERROR]: "button-error"
}

export const tagStatusClass = {
  [EModelAnalysisStatus.LOADING]: {
    text: "Checking for Manufacturing Issues....",
    icon: OrderReviewIcon,
    className: "corner-bottom-right-bevel-loading"
  },
  [EModelAnalysisStatus.SUCCESS]: {
    text: "Ready To Order",
    icon: SuccessToastIcon,
    className: "corner-bottom-right-bevel-success"
  },
  [EModelAnalysisStatus.WARNING]: {
    text: "Manufacturing Issues Detected",
    icon: WarningDFMIcon,
    className: "corner-bottom-right-bevel-warning"
  },
  [EModelAnalysisStatus.ERROR]: {
    text: "Manufacturing Issues Detected",
    icon: BlockIcon,
    className: "corner-bottom-right-bevel-error"
  }
}

export const ModelItemSkeleton = ({
  file,
  onDelete,
  onOpenManualRequest
}: {
  file: UploadFile
  onDelete: () => void
  onOpenManualRequest: (file: UploadFile) => void
}) => {
  return (
    <div className="model-item">
      <div className="col">
        <div className={`product-tag`}>
          <p>
            <img
              width={16}
              height={16}
              src={
                tagStatusClass[
                  file.status === "uploading"
                    ? EModelAnalysisStatus.LOADING
                    : EModelAnalysisStatus.ERROR
                ]?.icon
              }
              alt=""
            />
            <span>
              {file.status === "uploading" ? "Uploading Part" : "Upload Failed"}
            </span>
          </p>

          <div
            className={`corner-bottom-right-bevel ${
              tagStatusClass[
                file.status === "uploading"
                  ? EModelAnalysisStatus.LOADING
                  : EModelAnalysisStatus.ERROR
              ].className
            }`}
          />
        </div>
        <div className="container">
          <div className="col-1">
            {/* <CustomCheckbox disabled /> */}
            <div className="product-viewer">
              <Skeleton width={120} height={120} />
            </div>
            <div className="col-2-text">
              <div className="col-2-text-filename">{file.name}</div>
              <div className="col-2-size">
                <p>
                  <Skeleton width={120} height={20} />
                </p>
              </div>
              <div className="col-2-volume">
                <p>
                  <Skeleton width={60} height={20} />
                </p>
              </div>
              {/* <div>
                <Button
                  customSize={Size.EXTRA_SMALL}
                  hierarchy={ButtonHierarchy.TONAL}
                  customType={Type.NEUTRAL}
                  customClassName={["button", "button-loading"].join(" ")}
                >
                  Performing DFM Analysis...
                </Button>
              </div> */}
            </div>
          </div>

          <div className="col-3">
            <p>
              <Skeleton width={60} />
            </p>
            <p>
              <Skeleton width={120} />
            </p>
            <p>
              <Skeleton width={80} />
            </p>
            <p>
              <Skeleton width={180} />
            </p>
          </div>
          <div className="col-4">
            <p>Visual Inspection</p>
            {[6, 10, 8].map((item) => (
              <div key={`${item}-post`}>
                <p>
                  <Skeleton width={20 * item} />
                </p>
              </div>
            ))}
          </div>
          <div className="col-5" onClick={(e) => e.stopPropagation()}>
            <NumberField value={1} isNoneValue setValue={() => {}} disable />
          </div>
          <div className="col-6">
            <div className="col-6-text">
              <p>--.--</p>
              <p>--.-- / part</p>
            </div>
            <div className="col-6-action">
              <div />
              {file.status === "error" && (
                <Button hierarchy={ButtonHierarchy.LINK} onClick={onDelete}>
                  <DeleteOutlined />
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="footer">
          <span className="error-message">
            {file.error && (
              <>
                {file.error || ""}
                <>
                  <span onClick={() => onOpenManualRequest(file)}>
                    request manual review
                  </span>
                </>
              </>
            )}
          </span>

          <div className="date-delivery">
            <p>Ready soon as:</p>
            <p>---</p>
          </div>
        </div>
      </div>
    </div>
  )
}
