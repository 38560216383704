import RootContainer from "@/components/RootContainer/RootContainer"
import "./ContactUsPage.scss"
import { CanadaMap, HeadPhoneMicIcon, USDMap } from "@/assets"
import { useSelector } from "react-redux"
import { IUserStore } from "@/store/user"
import { RootState } from "@/store"

export default function ContactUsPage(): JSX.Element {
  const country = useSelector<RootState, IUserStore>((s) => s.user).userInfoNew
    .country

  const isCanada = country === "CA"

  const data = {
    titles: isCanada ? "Canadian" : "United States",
    bottomRowCountry: isCanada ? "Canada" : "North America",
    image: isCanada ? CanadaMap : USDMap,
    addressed: isCanada
      ? [
          [
            "Western Manufacturing Hub",
            "Forge Labs, Inc.",
            "Burnaby, British Columbia",
            "604-259-1399"
          ],
          [
            "Eastern Manufacturing Hub",
            "Forge Labs, Inc.",
            "Toronto, Ontario",
            "647-277-4199"
          ]
        ]
      : [
          [
            "Manufacturing Hub",
            "Forge Labs, Inc.",
            "9320 4th Ave S",
            "Seattle, Washington, 98108"
          ],
          [
            "Shipping & Receiving:",
            "9:00 AM - 4:00 PM M - F",
            "&nbsp;",
            "&nbsp;"
          ]
        ],
    links: isCanada
      ? [
          [
            {
              label: "Vancouver",
              link: "https://forgelabs.ca/"
            },
            {
              label: "Toronto",
              link: "https://forgelabs.ca/3d-printing-toronto/"
            },
            {
              label: "Edmonton",
              link: "https://forgelabs.ca/3d-printing-edmonton/"
            },
            {
              label: "Calgary",
              link: "https://forgelabs.ca/3d-printing-calgary/"
            },
            {
              label: "Montreal",
              link: "https://forgelabs.ca/3d-printing-montreal/"
            }
          ],
          [
            {
              label: "Ottawa",
              link: "https://forgelabs.ca/3d-printing-ottawa/"
            },
            {
              label: "Winnipeg",
              link: "https://forgelabs.ca/3d-printing-winnipeg/"
            },
            {
              label: "Victoria",
              link: "https://forgelabs.ca/3d-printing-victoria/"
            }
          ]
        ]
      : [
          [
            {
              label: "Seattle",
              link: "https://forgelabs.ca/"
            },
            {
              label: "Portland",
              link: "https://forgelabs.ca/3d-printing-toronto/"
            },
            {
              label: "San Francisco",
              link: "https://forgelabs.ca/3d-printing-edmonton/"
            },
            {
              label: "Los Angeles",
              link: "https://forgelabs.ca/3d-printing-ottawa/"
            }
          ],
          [
            {
              label: "Chicago",
              link: "https://forgelabs.ca/3d-printing-winnipeg/"
            },
            {
              label: "New York",
              link: "https://forgelabs.ca/3d-printing-victoria/"
            }
          ]
        ]
  }

  return (
    <RootContainer headerText="Contact">
      <div className="contact-us">
        <div className="contact-us-container">
          <div className="contact-us-container-top">
            <div className="contact-us-container-top-row row-1">
              <img src={HeadPhoneMicIcon} alt="img" />
              <div>
                <p className="contact-us-text-title">Get in touch with us!</p>
              </div>
            </div>

            <div className="contact-us-container-top-row row-2">
              <div>
                <p className="text-content">
                  Forge Labs sales and customer service teams are available to
                  assist with orders inquiries, materials and manufacturing
                  questions
                </p>
                <p className="text-content">
                  You can also contact us online at any time!
                </p>
              </div>
            </div>

            <div className="contact-us-container-top-row row-3">
              <div>
                <p className="text-content">Main line: 1.877.329.9131</p>
                <p className="text-content">E-mail: sales@forgelabs.com</p>
              </div>
            </div>

            <div className="contact-us-container-top-row row-4">
              <div>
                <p className="text-subtitle">Hours of Operation:</p>
                <p className="text-content">
                  8:30 am to 4:30 pm Monday to Friday.
                </p>
              </div>
            </div>
          </div>
          <div className="contact-us-container-bottom">
            <div className="contact-us-container-bottom-row-1">
              <div>
                <p className="contact-us-text-title">
                  {data.titles} Manufacturing
                </p>
              </div>
            </div>

            <div className="contact-us-container-bottom-row-2">
              <div className="contact-us-container-bottom-row-2-left">
                <div className="contact-us-container-bottom-row-2-left-row-1">
                  <div>
                    <p className="text-content">
                      Our nationwide manufacturing facilities ensure the rapid
                      turnaround of both prototypes and low-volume parts across{" "}
                      {data.bottomRowCountry}.
                    </p>
                  </div>
                </div>

                <div className="contact-us-container-bottom-row-2-left-row-2">
                  <div className="contact-us-container-bottom-row-2-left-row-2-left">
                    {data.addressed[0].map((e, index) => (
                      <p
                        key={index}
                        className={
                          index === 0 ? "text-subtitle" : "text-content"
                        }
                        dangerouslySetInnerHTML={{ __html: e }}
                      ></p>
                    ))}
                  </div>
                  <div className="contact-us-container-bottom-row-2-left-row-2-right">
                    {data.addressed[1].map((e, index) => (
                      <p
                        key={index}
                        className={
                          index === 0 ? "text-subtitle" : "text-content"
                        }
                        dangerouslySetInnerHTML={{ __html: e }}
                      ></p>
                    ))}
                  </div>
                </div>

                <div className="contact-us-container-bottom-row-2-left-row-3">
                  <div>
                    {data.links[0].map((e, index) => (
                      <a
                        key={index}
                        className="text-content"
                        target="_blank"
                        href={e.link}
                      >
                        {e.label}
                        {index !== data.links[0].length - 1 && " - "}
                      </a>
                    ))}
                  </div>
                  <div>
                    {data.links[1].map((e, index) => (
                      <a
                        key={index}
                        className="text-content"
                        target="_blank"
                        href={e.link}
                      >
                        {e.label}
                        {index !== data.links[1].length - 1 && " - "}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="contact-us-container-bottom-row-2-right">
                <img src={data.image}></img>
              </div>
            </div>
          </div>

          <div className="contact-us-container-session">
            <p>Session ID: 48654</p>
          </div>
        </div>
      </div>
    </RootContainer>
  )
}
