import {
  Button,
  CustomBadge,
  FormField,
  RadioButton,
  TextField
} from "@/components"
import { Radio, RadioChangeEvent, Upload, UploadFile, UploadProps } from "antd"
import React, { useEffect, useState } from "react"
import "./Payment.scss"
import { ECollapseKey, EPaymentMethod } from "@/enums/checkout.enum"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ICheckoutForm, IPaymentForm } from "@/interfaces/checkout.interface"
import {
  DEFAULT_COUNTRY_ID,
  EMTY_STATE,
  errorStatus
} from "@/constants/common.constant"
import { IBillingFormValues } from "@/interfaces/billing.interface"
import BillingInfoEditForm from "./components/BillingInfoEditForm/BillingInfoEditForm"
import {
  BadgeColor,
  BadgeType,
  ButtonHierarchy,
  Size,
  Type
} from "@/enums/common.enum"
import { PDFIcon, uploadPDFIcon } from "@/assets"
import { CloseOutlined } from "@ant-design/icons"
import { BILLING_INFORMATION_SCHEMA } from "@/validations/billing.validation"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useSelector } from "react-redux"
import _ from "lodash"
interface IPaymentProps {
  onSubmit: (data?: IPaymentForm) => void
  prefillData?: ICheckoutForm
  handleChangePaymentMethod: (e: RadioChangeEvent) => void
  value: string
  handlePreStep: () => void
  setRemovedKey: React.Dispatch<React.SetStateAction<ECollapseKey | undefined>>
}
const Payment: React.FC<IPaymentProps> = ({
  onSubmit,
  prefillData,
  handleChangePaymentMethod,
  value,
  handlePreStep,
  setRemovedKey
}) => {
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const last_billing_address = userInfoNew?.last_billing_info
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const defaultBillingFormValues: IBillingFormValues = {
    firstName:
      prefillData?.billing?.firstName || last_billing_address?.name || "",
    lastName:
      prefillData?.billing?.lastName || last_billing_address?.surname || "",
    phoneNumber:
      prefillData?.billing?.phoneNumber || last_billing_address?.phone || "",
    accountPayEmail:
      prefillData?.billing?.accountPayEmail ||
      last_billing_address?.email ||
      "",
    state: prefillData?.billing?.state || EMTY_STATE,
    country: prefillData?.billing?.country || {
      id: DEFAULT_COUNTRY_ID,
      name: "Canada",
      iso2: "CA"
    },
    addressLine1:
      prefillData?.billing?.addressLine1 ||
      last_billing_address?.street_address ||
      "",
    addressLine2:
      prefillData?.billing?.addressLine2 !== undefined
        ? prefillData?.billing?.addressLine2
        : last_billing_address?.apartment,
    company:
      prefillData?.billing?.company !== undefined
        ? prefillData?.billing?.company
        : last_billing_address?.company_name,
    city: prefillData?.billing?.city || last_billing_address?.city || "",
    zipCode:
      prefillData?.billing?.zipCode || last_billing_address?.postcode || "",
    taxNumber:
      prefillData?.billing?.taxNumber || last_billing_address?.vat_number || "",
    poNumber: prefillData?.po_number || last_billing_address?.po_number || "",
    poFile: prefillData?.attached_po ? [prefillData?.attached_po] : []
  }
  const methods = useForm<IBillingFormValues>({
    resolver: yupResolver(BILLING_INFORMATION_SCHEMA),
    defaultValues: defaultBillingFormValues
  })
  const { control, handleSubmit, formState, watch, reset, setValue, setError } =
    methods

  const { errors, defaultValues, isSubmitting, submitCount } = formState
  const formData = watch()

  useEffect(() => {
    if (prefillData) {
      reset(defaultBillingFormValues)
    }
  }, [prefillData])

  useEffect(() => {
    if (value !== EPaymentMethod.CREDIT_CARD) {
      const data = _.omitBy(formData, _.isUndefined)
      if (
        !isSubmitting &&
        (Object.keys(errors).length || !_.isEqual(data, defaultValues))
      ) {
        setRemovedKey(ECollapseKey.PAYMENT)
      }
    }
  }, [formData])

  const onSubmitDataBilling = (data: IBillingFormValues) => {
    const mappedData: IPaymentForm = {
      billing: { ...data, poNumber: watch("poNumber") },
      poFile: fileList[0]
    }
    onSubmit(mappedData)
  }

  const handleChangeFile: UploadProps["onChange"] = ({ file }) => {
    setFileList([file])
    const isPDF = file.type === "application/pdf"

    if (!isPDF)
      setError("poFile", {
        type: "isValid",
        message: "Only PDF file are allowed."
      })

    setValue("poFile", [file], { shouldValidate: true })
  }

  const handleRemoveFile = (file: UploadFile) => {
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid))
    setValue("poFile", [], {
      shouldValidate: true
    })
  }

  const getBorder = (value: string) => {
    if (value !== EPaymentMethod.CREDIT_CARD) return "border-payment"
  }

  const textUpload = !!fileList.length ? "Replace PDF" : "Upload PDF"

  return (
    <>
      <div className={`payment-method-wrapper ${getBorder(value)}`}>
        <p className="payment-method-title">PAYMENT METHOD</p>
        {value === EPaymentMethod.PURCHASE_ORDER && (
          <p className="payment-method-text-field">Required Field *</p>
        )}
        <div className="payment-method">
          <div className="payment-method-left">
            <Radio.Group value={value} onChange={handleChangePaymentMethod}>
              <div className="payment-method-left-radio">
                <div>
                  <RadioButton value={EPaymentMethod.CREDIT_CARD}>
                    Credit Card
                  </RadioButton>
                </div>
                <div>
                  <RadioButton value={EPaymentMethod.PURCHASE_ORDER}>
                    Purchase Order
                  </RadioButton>
                </div>
              </div>
            </Radio.Group>
          </div>
          <div className="payment-method-right">
            {value === EPaymentMethod.CREDIT_CARD ? (
              <></>
            ) : (
              <>
                <FormField
                  textLabel="PO number *"
                  errorText={errors.poNumber?.message}
                  clasName="payment-method-right-po"
                >
                  <Controller
                    control={control}
                    name="poNumber"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        placeholder="Input number"
                        status={errorStatus(errors.poNumber)}
                      ></TextField>
                    )}
                  ></Controller>
                </FormField>
                <FormField
                  textLabel="Purchase Order Document *"
                  errorText={errors.poFile?.message}
                >
                  <Controller
                    control={control}
                    name="poNumber"
                    render={({ field }) => (
                      <>
                        <div
                          className={`payment-method-right-content ${
                            errors.poFile?.message
                              ? "payment-method-right-content-error"
                              : ""
                          }`}
                        >
                          {fileList?.length > 0 ? (
                            <CustomBadge
                              content={""}
                              size={Size.MEDIUM}
                              type={BadgeType.TONAL}
                              color={BadgeColor.ROLE}
                            >
                              <div
                                key={fileList[0].uid}
                                className="item-render-pdf"
                              >
                                <img src={PDFIcon} alt="" />
                                <p>{fileList[0].name}</p>
                                <div className="icon-box">
                                  <CloseOutlined
                                    onClick={() =>
                                      handleRemoveFile(fileList[0])
                                    }
                                    className="icon-box-render"
                                  />
                                </div>
                              </div>
                            </CustomBadge>
                          ) : (
                            <p></p>
                          )}
                          <Upload
                            accept="*/*"
                            fileList={fileList}
                            onChange={handleChangeFile}
                            showUploadList={false}
                          >
                            <Button
                              customSize={Size.SMALL}
                              hierarchy={ButtonHierarchy.LINK}
                              customClassName="upload-btn"
                            >
                              <img src={uploadPDFIcon} alt="" />
                              <p className="title-upload">{textUpload}</p>
                            </Button>
                          </Upload>
                        </div>
                      </>
                    )}
                  ></Controller>
                </FormField>
              </>
            )}
          </div>
        </div>
      </div>
      {value !== EPaymentMethod.CREDIT_CARD ? (
        <FormProvider {...methods}>
          <div className="billing-info">
            <div className="billing-info-box">
              <BillingInfoEditForm
                formState={formState}
                handleSubmit={handleSubmit}
                onSubmit={onSubmitDataBilling}
                handlePreStep={handlePreStep}
                prefillData={prefillData}
              />
            </div>
          </div>
        </FormProvider>
      ) : (
        <div className="collapse-footer">
          <Button
            customType={Type.NEUTRAL}
            hierarchy={ButtonHierarchy.OUTLINE}
            onClick={handlePreStep}
          >
            Previous Step
          </Button>
          <Button onClick={() => onSubmit()}>Confirm</Button>
        </div>
      )}
    </>
  )
}

export default Payment
