import { priceDisplay } from "@/utils/functionHelper"
import "./InvoiceItem.scss"
import { LineItem } from "@/services/apiDigifabster/quote"
import { calculatePerPartCost } from "@/utils/dataHelper"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"

interface IInvoiceItemProps {
  item: LineItem
  currency: string
}

function InvoiceItem({ item, currency }: IInvoiceItemProps) {
  const { invoiceDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)

  return (
    <div className="invoice-item-box">
      <div className="invoice-item-box-col-1">
        <div>
          <img src={item.product.thumb_120x120} alt="" width={68} height={54} />
        </div>
        <p>{item.product.title}</p>
      </div>
      <div className="invoice-item-box-col-2">
        <p>{item.technology_title}</p>
        <p>
          {item.material_title} ,{item.config.color.name_for_user}
        </p>
        <p>{item.config.lead_time.name_for_user}</p>
      </div>
      <div className="invoice-item-box-col-3">
        <p>{item.amount}</p>
      </div>
      <div className="invoice-item-box-col-4">
        <p>
          {currency}
          {calculatePerPartCost(
            item.models_price,
            invoiceDetail?.order?.product_count,
            Number(invoiceDetail?.order?.startup_cost),
            item.amount
          )}
        </p>
        <p>
          {currency}
          {calculatePerPartCost(
            item.unit_price,
            invoiceDetail?.order?.product_count,
            Number(invoiceDetail?.order?.startup_cost)
          )}{" "}
          / unit
        </p>
      </div>
    </div>
  )
}

export default InvoiceItem
