import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import CheckOutForm from "./components/CheckOutForm"
import "./StripePage.scss"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useCurrency } from "@/hooks/useCurrency"
import { priceDisplay } from "@/utils/functionHelper"
import stripeConfig from "@/config/stripe"
import { calculatePerPartCost } from "@/utils/dataHelper"
import {
  OrderDetailResponse,
  useLazyGetQuoteDetailQuery
} from "@/services/apiDigifabster/quote"
import { getInvoiceHash } from "@/utils/stringHelper"
import { RootContainer } from "@/components"
import { useAppLoading } from "@/hooks/useLoading"

const stripePromise = loadStripe(stripeConfig.apiKey || "")

const StripePage = () => {
  const { clientSecret, orderId, invoice_hash, invoiceId } = useParams()
  const [getOrderDetail] = useLazyGetQuoteDetailQuery()
  const [invoiceDetail, setInvoiceDetail] = useState<
    OrderDetailResponse | undefined
  >(undefined)
  const { currency } = useCurrency()

  const navigate = useNavigate()

  const getInvoice = async () => {
    const { data } = await getOrderDetail({
      quoteId: orderId || ""
    })
    setInvoiceDetail(data)
  }

  const options = {
    clientSecret: clientSecret
  }

  useEffect(() => {
    getInvoice()
  }, [])

  const handleBack = () => {
    if (invoiceId && invoice_hash)
      return navigate(
        `/quotes/invoices/${invoiceId}/${invoice_hash}/checkout/${orderId}`
      )
    return navigate(`/new-quote/checkout/${orderId}`)
  }

  if (!invoiceDetail) return <></>
  return (
    <RootContainer onBack={handleBack} headerText="Payment">
      <div className="stripe-container">
        <div className="stripe-container-left">
          <p className="title">Pay Forge Labs Inc.</p>
          <p className="subtitle">
            {currency}
            {priceDisplay(invoiceDetail?.total_price || 0)}
          </p>

          <div className="items-list">
            {invoiceDetail?.line_items?.map((e) => {
              return (
                <div key={e.id} className="items-list-item">
                  <img src={e.product.thumb_120x120}></img>
                  <p>{e.product.title}</p>
                  <p>
                    {currency}
                    {calculatePerPartCost(
                      e.total_price,
                      invoiceDetail.product_count,
                      Number(invoiceDetail.startup_cost)
                    )}
                  </p>
                </div>
              )
            })}
          </div>

          <div className="tax-section">
            <p></p>
            <p>Tax</p>
            <p>
              {currency}
              {priceDisplay(invoiceDetail?.tax_value || 0)}
            </p>
          </div>
          <div className="subtotal-section">
            <p></p>
            <p>Subtotal</p>
            <p>
              {currency}
              {priceDisplay(invoiceDetail?.models_price || 0)}
            </p>
          </div>
          <div className="shipping-section">
            <p></p>
            <p>Shipping</p>
            <p>
              {currency}
              {priceDisplay(invoiceDetail?.delivery_price || 0)}
            </p>
          </div>
          <div className="total-section">
            <p></p>
            <p>Total due</p>
            <p>
              {currency}
              {priceDisplay(invoiceDetail?.total_price || 0)}
            </p>
          </div>
        </div>
        <div className="stripe-container-right">
          <Elements stripe={stripePromise} options={options}>
            <CheckOutForm
              invoiceId={invoiceDetail?.main_invoice?.id}
              invoiceHash={getInvoiceHash(invoiceDetail?.main_invoice?.pay_url)}
            />
          </Elements>
        </div>
      </div>
    </RootContainer>
  )
}

export default StripePage
