import RootContainer from "@/components/RootContainer/RootContainer"
import "./CheckoutPage.scss"
import OrderSummary from "./components/OrderSummary/OrderSummary"
import { Button, HeadingCollapse, Modal } from "@/components"
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DeliveryIcon,
  downloadIcon,
  OrderReviewIcon,
  PaymentIcon,
  ShippingIcon
} from "@/assets"
import { useEffect, useMemo, useRef, useState } from "react"
import { useBlocker, useNavigate, useParams } from "react-router-dom"
import { useAppLoading } from "@/hooks/useLoading"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/store"
import {
  IQuoteStore,
  removeDonKeysCheckout,
  setClickableKeysCheckout,
  setDoneKeysCheckout,
  setQuoteShippingAndTaxPrice
} from "@/store/quote"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import DeliveryAddress from "./components/DeliveryAddress/DeliveryAddress"
import OrderReview from "./components/OrderReview/OrderReview"
import ShippingMethod from "./components/ShippingMethod/ShippingMethod"
import Payment from "./components/Payment/Payment"
import { IDeliveryForm } from "@/interfaces/delivery.interface"
import { ICheckoutForm, IPaymentForm } from "@/interfaces/checkout.interface"
import {
  ECollapseKey,
  EPaymentMethod,
  EShippingMethod
} from "@/enums/checkout.enum"
import { Collapse, ConfigProvider, RadioChangeEvent } from "antd"

import * as toast from "@/utils/Toast"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { ShippingRateResponse } from "@/services/apiDigifabster/shipping"
import { INotesForm } from "@/interfaces/notes.interface"
import {
  useLazyGetQuoteDetailQuery,
  useLazyGetQuoteQuery
} from "@/services/apiDigifabster/quote"
import { useLazyGetMeQuery } from "@/services/apiDigifabster/user"
import ModalWarning from "./components/ModalWarning/ModalWarning"
import { useCheckoutFlow } from "@/hooks"
import { IAuthStore } from "@/store/auth"
import { IUserStore } from "@/store/user"

export default function CheckoutPage(): JSX.Element {
  const { currentQuote, doneKeysCheckout, clickableKeysCheckout } = useSelector<
    RootState,
    IQuoteStore
  >((s) => s.quote)
  const { settings } = useSelector((state: RootState) => state.user)
  const { isLogOut } = useSelector<RootState, IAuthStore>((state) => state.auth)
  let { quoteId, invoice_hash, invoiceId } = useParams()
  const [getQuote, { data, isLoading, isError, isFetching }] =
    useLazyGetQuoteQuery()
  const [getOrderDetail, { data: orderData, isFetching: isFetchingOrderData }] =
    useLazyGetQuoteDetailQuery()
  const [getMe, { isLoading: isLoadingUser, isFetching: isFetchingUser }] =
    useLazyGetMeQuery()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    sumbitOrder,
    sumbitQuote,
    updatePO,
    getShippingRateAndTax,
    beforeUnloadHandler,
    redirectInit,
    backFunction,
    disabledSubmit,
    goToPaymentPage,
    isLoadingSubmitOrder,
    isLoadingUpdateInvoice,
    getShippingRateLoading,
    getTaxRateLoading,
    getPaymentLinkLoading,
    isLoadingSubmitQuote
  } = useCheckoutFlow()

  useAppLoading([
    isLoading,
    isLoadingUser,
    isLoadingUpdateInvoice,
    isLoadingSubmitOrder,
    isFetchingOrderData,
    isFetching,
    isFetchingUser,
    getPaymentLinkLoading,
    isLoadingSubmitQuote
  ])
  const [activeKey, setActiveKey] = useState<number>(1)
  const [formData, setFormData] = useState<ICheckoutForm>({} as ICheckoutForm)
  const [shippingMethod, setShippingMethod] = useState(EShippingMethod.DELIVERY)
  const [isEditDelivery, setIsEditDelivery] = useState<boolean>(true)
  const [removedKey, setRemovedKey] = useState<ECollapseKey>()
  const [paymentMethod, setPaymentMethod] = useState<string>(
    EPaymentMethod.CREDIT_CARD
  )
  const [shippingRate, setShippingRate] = useState<ShippingRateResponse[]>([])
  const dispatch = useDispatch()
  const isRequiresReview =
    currentQuote?.available_order_initial_statuses?.[0] ===
    EOrderStatus.WAITING_FOR_REVIEW
  const [confirmModal, setConfirmModal] = useState(false)
  const navigate = useNavigate()
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)

  useEffect(() => {
    if (quoteId) {
      getData(quoteId)
      dispatch(setClickableKeysCheckout([1]))
      dispatch(setDoneKeysCheckout([]))
    }
  }, [quoteId])

  const getData = async (quoteId: string) => {
    await getMe()
    await getQuote({ quoteId })
    await getOrderDetail({ quoteId })
  }

  useEffect(() => {
    if (isSubmitting || isLogOut) return

    const handleBeforeUnload = beforeUnloadHandler(
      formData,
      isSubmitting,
      isLogOut
    )

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [formData, isSubmitting, isLogOut])

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (isSubmitting) return false
    if (
      Object.keys(formData).length > 0 &&
      currentLocation.pathname !== nextLocation.pathname
    ) {
      setConfirmModal(true)
      return true
    }
    return false
  })

  const handleCancel = () => {
    setConfirmModal(false)
    blocker.reset?.()
  }

  const handleOk = () => {
    setConfirmModal(false)
    blocker.proceed?.()
  }

  useEffect(() => {
    if (isError) {
      return navigate(`/not-found`)
    }
  }, [isError])

  useEffect(() => {
    redirectInit(currentQuote, invoiceId, invoice_hash)
  }, [currentQuote])

  const handleChangeCollapse = (key: string | string[]) => {
    const currentKey = Array.isArray(key) ? Number(key[1]) : Number(key)
    const isDoneStep = clickableKeysCheckout.includes(currentKey)
    if (!isDoneStep) return
    setActiveKey(currentKey)
  }

  const onSubmitNotes = async (data: INotesForm) => {
    !!data.notes?.length && setFormData({ ...formData, notes: data.notes })
    handleNextStep()
  }

  const handleNextStep = () => {
    const nextKey = activeKey + 1
    setActiveKey(nextKey)
    setRemovedKey(undefined)
    dispatch(setClickableKeysCheckout([...clickableKeysCheckout, nextKey]))
    dispatch(setDoneKeysCheckout([...doneKeysCheckout, activeKey]))
  }

  const handlePrevStep = () => {
    const nextKey = activeKey - 1
    setActiveKey(nextKey)
  }

  useEffect(() => {
    if (removedKey) {
      dispatch(removeDonKeysCheckout(removedKey))
    }
  }, [removedKey, activeKey])

  const onSubmitDelivery = async (data: IDeliveryForm) => {
    try {
      handleNextStep()
      setFormData({ ...formData, delivery_address: data })
      setIsEditDelivery(false)
      const results = await getShippingRateAndTax(currentQuote?.id || 0, data)
      const shippingRes =
        results[0].status === "fulfilled" ? results[0].value : null
      const taxRes = results[1].status === "fulfilled" ? results[1].value : null

      if (shippingRes?.error || taxRes?.error) {
        if (shippingRes?.error) setShippingRate([])
        dispatch(
          setQuoteShippingAndTaxPrice({
            shippingPrice: 0,
            taxRate: 0
          })
        )
      }

      const shippingRateRes = shippingRes?.data
      const taxRateRes = taxRes?.data
      if (shippingRateRes) {
        const _shippingRateRes = shippingRateRes.map((item) => ({
          ...item,
          price:
            userInfoNew.country === "US"
              ? shippingRateRes[0]?.price
              : shippingRateRes[0]?.price / Number(userInfoNew.rate || 1)
        }))
        setShippingRate(_shippingRateRes)
        if (
          formData.shipping_method === EShippingMethod.DELIVERY ||
          !formData.shipping_method
        ) {
          dispatch(
            setQuoteShippingAndTaxPrice({
              shippingPrice: _shippingRateRes[0]?.price || 0,
              taxRate: taxRateRes?.tax
            })
          )
        }
      }
    } catch (_) {
      setShippingRate([])
      dispatch(
        setQuoteShippingAndTaxPrice({
          shippingPrice: 0,
          taxRate: 0
        })
      )
    }
  }

  const onSubmitPayment = (data?: IPaymentForm) => {
    if (data) {
      const { poFile, poNumber, billing } = data
      setFormData({
        ...formData,
        attached_po: poFile || ({} as any),
        billing,
        po_number: billing?.poNumber || ""
      })
    }
    handleNextStep()
  }

  const onChangeMethod = (data: {
    method: EShippingMethod
    carrier?: string
    note?: string
  }) => {
    setShippingMethod(data.method)
    setFormData({
      ...formData,
      shipping_method: data.method,
      shipping_carrier: data.carrier,
      shipping_note: data.note
    })
    handleNextStep()
  }

  const handleChangePaymentMethod = (e: RadioChangeEvent) => {
    setRemovedKey(ECollapseKey.PAYMENT)
    setPaymentMethod(e.target.value)
  }

  const handleSubmit = async () => {
    try {
      if (!currentQuote) return

      setIsSubmitting(true)

      const currentStatus = isRequiresReview
        ? EOrderStatus.WAITING_FOR_REVIEW
        : (currentQuote?.status as EOrderStatus) || EOrderStatus.INITIAL
      const { attached_po, po_number } = formData

      if (!isRequiresReview) {
        const resSubmitOrder = await sumbitOrder(
          formData,
          currentStatus,
          currentQuote.id || 0,
          paymentMethod as EPaymentMethod
        )
        const errorMsg = (resSubmitOrder.error as any)?.data?.message || ""
        if (resSubmitOrder.error || !resSubmitOrder.data)
          throw new Error(errorMsg)
        const invoice_id = invoiceId
          ? Number(invoiceId)
          : Number(resSubmitOrder.data.invoiceId)
        const invoiceHash = invoice_hash
          ? invoice_hash
          : resSubmitOrder.data.invoiceHash

        if (paymentMethod === EPaymentMethod.PURCHASE_ORDER) {
          await updatePO(invoice_id, invoiceHash, attached_po, po_number)
          if (invoiceId && invoice_hash)
            return navigate(
              `/new-quote/checkout/${currentQuote?.id}/submitted/invoices/${invoiceId}/${invoice_hash}`
            )
          return navigate(`/new-quote/checkout/${currentQuote?.id}/submitted`)
        }
        await goToPaymentPage(
          currentQuote.id || 0,
          resSubmitOrder.data.clientSecret,
          invoice_hash,
          invoiceId
        )
      } else {
        const resSubmitOrder = await sumbitQuote(
          formData,
          currentStatus,
          currentQuote.id || 0,
          paymentMethod as EPaymentMethod
        )
        const errorMsg = (resSubmitOrder.error as any)?.data?.message || ""
        if (resSubmitOrder.error || !resSubmitOrder.data)
          throw new Error(errorMsg)
        const invoice_id = invoiceId
          ? Number(invoiceId)
          : Number(resSubmitOrder.data.invoiceId)
        const invoiceHash = invoice_hash
          ? invoice_hash
          : resSubmitOrder.data.invoiceHash

        navigate(`/quotes/invoices/${invoice_id}/${invoiceHash}`)
      }
    } catch (err) {
      console.log("err", err)
      const message = err as any
      toast.showError(message.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  const submitText = useMemo(() => {
    if (isRequiresReview) return "Submit for review"
    return "Place Order"
  }, [activeKey, paymentMethod, isRequiresReview])

  const disabled = useMemo(() => {
    return disabledSubmit(isRequiresReview, doneKeysCheckout)
  }, [activeKey, doneKeysCheckout, getShippingRateLoading, getTaxRateLoading])

  if (isFetching || isFetchingOrderData || isFetchingUser) return <></>

  const itemSections = [
    {
      key: ECollapseKey.ORDER_REVIEW,
      label: (
        <>
          <HeadingCollapse
            title="Order Review"
            prefix={OrderReviewIcon}
            subtitle="Review quantities and production timelines."
            isDone={doneKeysCheckout.includes(ECollapseKey.ORDER_REVIEW)}
          />
        </>
      ),
      children: <OrderReview onSubmit={onSubmitNotes} />
    },
    {
      key: ECollapseKey.DELIVERY_ADDRESS,
      label: (
        <>
          <HeadingCollapse
            title="Delivery Address"
            prefix={DeliveryIcon}
            subtitle="Enter the address where your order will be delivered."
            isDone={doneKeysCheckout.includes(ECollapseKey.DELIVERY_ADDRESS)}
          />
        </>
      ),
      children: (
        <DeliveryAddress
          onSubmit={onSubmitDelivery}
          prefillData={formData.delivery_address}
          isEdit={isEditDelivery}
          handlePreStep={handlePrevStep}
          setRemovedKey={setRemovedKey}
        />
      )
    },
    {
      key: ECollapseKey.SHIPPING_METHOD,
      label: (
        <>
          <HeadingCollapse
            title="Shipping Method"
            prefix={ShippingIcon}
            subtitle="Select your shipping method and delivery speed."
            isDone={doneKeysCheckout.includes(ECollapseKey.SHIPPING_METHOD)}
          />
        </>
      ),
      children: (
        <ShippingMethod
          state={formData?.delivery_address?.state?.iso2}
          shippingRate={shippingRate}
          handlePreStep={handlePrevStep}
          onChange={onChangeMethod}
          setRemovedKey={setRemovedKey}
          isLoading={getShippingRateLoading || getTaxRateLoading}
          prefillMethod={formData.shipping_method}
        />
      )
    },
    {
      key: ECollapseKey.PAYMENT,
      label: (
        <>
          <HeadingCollapse
            title="Payment"
            prefix={PaymentIcon}
            subtitle="Select your payment method to complete the purchase."
            isDone={doneKeysCheckout.includes(ECollapseKey.PAYMENT)}
          />
        </>
      ),
      children: (
        <Payment
          onSubmit={onSubmitPayment}
          prefillData={formData}
          value={paymentMethod}
          handleChangePaymentMethod={handleChangePaymentMethod}
          handlePreStep={handlePrevStep}
          setRemovedKey={setRemovedKey}
        />
      )
    }
  ]

  const itemsRequires = itemSections.filter(
    (item) => item.key !== ECollapseKey.PAYMENT
  )

  const handleBack = () => {
    backFunction(currentQuote, invoice_hash, invoiceId)
  }

  return (
    <RootContainer
      onBack={handleBack}
      headerText="Checkout"
      subHeaderText={`${currentQuote?.id ? `Q-${currentQuote?.id}` : ""}`}
    >
      {confirmModal && (
        <ModalWarning
          openModal={confirmModal}
          closeModal={handleCancel}
          onLeave={handleOk}
        ></ModalWarning>
      )}
      <div className="checkout-container">
        <div className="checkout-left">
          <div className="checkout-review-container">
            <div className="checkout-review">
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: '"Open Sans", sans-serif'
                  }
                }}
              >
                <Collapse
                  ghost
                  items={isRequiresReview ? itemsRequires : itemSections}
                  expandIconPosition="end"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <>
                        <img
                          src={ChevronUpIcon}
                          height={20}
                          width={20}
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={ChevronDownIcon}
                          height={20}
                          width={20}
                          alt=""
                        />
                      </>
                    )
                  }
                  onChange={handleChangeCollapse}
                  activeKey={activeKey}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
        <div className="checkout-right">
          <OrderSummary
            paymentMethod={paymentMethod}
            orderData={formData}
            isLoading={getShippingRateLoading || getTaxRateLoading}
            handleSubmit={handleSubmit}
            disabled={disabled}
            submitText={submitText}
          />
          <div className="checkout-right-footer">
            <div className="btn-download-box">
              <Button
                customSize={Size.LARGE}
                hierarchy={ButtonHierarchy.OUTLINE}
                customType={Type.NEUTRAL}
                onClick={() => {
                  window.open(currentQuote?.pdf_url || "", "_blank")
                }}
              >
                <img src={downloadIcon} alt="" />
                Download Draft Quote
              </Button>
            </div>
            <div className="checkout-question">
              Have Questions?{" "}
              <a href={`mailto:${settings.support_email}`}>Ask for help</a>
            </div>
          </div>
        </div>
      </div>
    </RootContainer>
  )
}
