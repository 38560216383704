import { Form, Radio, RadioChangeEvent } from "antd"
import { useEffect, useState } from "react"
import { FormField, RadioButton, TextField } from "@/components"
import "./MethodCollect.scss"
import { Controller, FieldError, useFormContext } from "react-hook-form"
import { mockMethod } from "@/constants/method-shipping.constant"
import { debounce } from "@/utils/functionHelper"
import { errorStatus } from "@/constants/common.constant"
import { ESHIPPING_METHOD } from "@/enums/shipping.enums"
interface IAccountNumberFormInput {
  accountNumber: string
}
interface IMethodCollectProps {
  onChangeCarrier: (e: string) => void
  onChangeNote: (e: string) => void
}

function MethodCollect(props: IMethodCollectProps) {
  const { onChangeCarrier, onChangeNote } = props
  const [value, setValue] = useState("Purolator")
  const debounceChangeNote = debounce(onChangeNote, 500)
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useFormContext<IAccountNumberFormInput>()

  useEffect(() => {
    onChangeCarrier(value)
  }, [value])

  const handleChangeMethod = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  const onSubmit = (data: IAccountNumberFormInput) => {
    onChangeNote(data.accountNumber)
  }

  return (
    <div className="shipping-method-box-collect">
      <p className="title-method">SELECT COURIER</p>
      {mockMethod.map((item, index) => (
        <div className="shipping-method-box-collect-item" key={index + 1}>
          <div>
            <Radio.Group onChange={handleChangeMethod} value={value}>
              <RadioButton value={item.name}></RadioButton>
            </Radio.Group>
          </div>
          <div className="img-box">
            <img src={item.img} alt="" />
            <p className="img-box-name">{item.name}</p>
          </div>
          <p className="time-text">{item.time}</p>
        </div>
      ))}
      {
        <Form onChange={handleSubmit(onSubmit)}>
          <FormField
            textLabel={
              value === ESHIPPING_METHOD.FEDEX
                ? "FEDEX ACCOUNT"
                : "ACCOUNT NUMBER"
            }
            errorText={errors.accountNumber?.message as string}
          >
            <Controller
              name="accountNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  status={errorStatus(errors.accountNumber as FieldError)}
                  placeholder="Enter shipping account number"
                ></TextField>
              )}
            ></Controller>
          </FormField>
        </Form>
      }
    </div>
  )
}

export default MethodCollect
