import {
  BillingAddressResponseDto,
  ShippingAddressResponseDto,
  UserResponseDto,
  userService
} from "@/services/api/user"

import { userService as userServiceNew } from "@/services/apiDigifabster/user"

import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit"
import { ISettingsResponse, UserInfoResponse } from "./type"

export interface IUserStore {
  userInfo: UserResponseDto
  userInfoNew: UserInfoResponse
  shippingAddresses: ShippingAddressResponseDto[]
  shippingDetail?: ShippingAddressResponseDto
  billingAddress?: BillingAddressResponseDto
  settings: ISettingsResponse
}
const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {} as UserResponseDto,
    userInfoNew: {} as UserInfoResponse,
    shippingAddresses: [],
    billingAddress: undefined,
    settings: {} as ISettingsResponse
  } as IUserStore,
  reducers: {
    deleteShippingAddress: (state, { payload }: PayloadAction<string>) => {
      state.shippingAddresses = state.shippingAddresses.filter(
        (e) => e._id !== payload
      )
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        userService.endpoints.getUser.matchFulfilled,
        userService.endpoints.updateUser.matchFulfilled
      ),
      (state, { payload }) => {
        state.userInfo = payload.data
      }
    )

    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.getMe.matchFulfilled),
      (state, { payload }) => {
        state.userInfoNew = payload
      }
    )

    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.updateUserInfo.matchFulfilled),
      (state, { payload }) => {
        state.userInfoNew = { ...state.userInfoNew, ...payload }
      }
    )

    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.updateUserCountry.matchFulfilled),
      (state, { meta }) => {
        const country = meta.arg.originalArgs.country
        state.userInfoNew = { ...state.userInfoNew, country }
      }
    )

    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.getSettings.matchFulfilled),
      (state, { payload }) => {
        state.settings = payload
      }
    )

    builder.addMatcher(
      userService.endpoints.getShippingAddresses.matchFulfilled,
      (state, { payload }) => {
        state.shippingAddresses = payload.data
      }
    )

    builder.addMatcher(
      userService.endpoints.getOneShippingAddress.matchFulfilled,
      (state, { payload }) => {
        state.shippingDetail = payload.data
      }
    )

    builder.addMatcher(
      userService.endpoints.getBillingAddress.matchFulfilled,
      (state, { payload }) => {
        state.billingAddress = payload.data
      }
    )
  }
})
export const { deleteShippingAddress } = userSlice.actions
export const userReducer = userSlice.reducer
