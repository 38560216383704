import React from "react"
import "./HeadingCollapse.scss"
import { SuccessToastIcon } from "@/assets"

interface IHeadingCollapseProps {
  prefix?: string
  title: string
  subtitle?: string
  isDone?: boolean
}
const HeadingCollapse: React.FC<IHeadingCollapseProps> = ({
  prefix,
  title,
  subtitle,
  isDone = false
}) => {
  return (
    <div className="heading-collapse-wrapper">
      <div className="heading-collapse-container">
        {prefix && <img src={prefix} />}
        <div className="">
          <h4>{title}</h4>
          <p>{subtitle}</p>
        </div>
      </div>

      {isDone && <img src={SuccessToastIcon} alt="" />}
    </div>
  )
}

export default HeadingCollapse
