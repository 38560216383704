import {
  REGEX_EMAIL,
  REGEX_ONLY_NUMBER,
  REQUIRED_ERROR,
  REQUIRED_ERROR_FIELD
} from "@/constants/common.constant"
import { IState } from "@/interfaces/common.interface"
import { mixed, object, string } from "yup"

export const DELIVERY_SCHEMA = object({
  name: string().required(REQUIRED_ERROR_FIELD()),
  surname: string().required(REQUIRED_ERROR_FIELD()),
  phoneNumber: string()
    .matches(REGEX_ONLY_NUMBER, "Phone number can only contain numbers")
    .length(10, "Phone number must be exactly 10 digits")
    .required(REQUIRED_ERROR_FIELD()),
  email: string()
    .required(REQUIRED_ERROR_FIELD())
    .matches(REGEX_EMAIL, "Invalid format"),
  additionalEmail: string(),
  company: string(),
  addressLine1: string().required(REQUIRED_ERROR_FIELD()),
  addressLine2: string(),
  city: string().required(REQUIRED_ERROR_FIELD()),
  state: mixed<IState>()
    .required(REQUIRED_ERROR_FIELD())
    .test("required", REQUIRED_ERROR_FIELD(), (value) => {
      if (value) {
        return !!value.id || !!value.name
      }
      return true
    }),
  zipCode: string().required(REQUIRED_ERROR_FIELD()),
  country: mixed<IState>().required(REQUIRED_ERROR_FIELD())
})
