import React from "react"
import "./OrderSummary.scss"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { Loading, Button } from "@/components"
import { priceDisplay } from "@/utils/functionHelper"
import { useCurrency } from "@/hooks/useCurrency"
import { ECollapseKey, EPaymentMethod } from "@/enums/checkout.enum"
import { ICheckoutForm } from "@/interfaces/checkout.interface"
import {
  billingAddressSectionIcon,
  DeliveryAddressIcon,
  shippingMethodIcon
} from "@/assets"
import { EShippingMethod } from "@/enums/checkout.enum"
import { Size } from "@/enums/common.enum"

interface IOrderSummaryProps {
  isLoading?: boolean
  orderData: ICheckoutForm
  paymentMethod: string
  handleSubmit: () => void
  disabled: boolean
  submitText: string
}
const OrderSummary: React.FC<IOrderSummaryProps> = ({
  isLoading = false,
  paymentMethod,
  orderData,
  handleSubmit,
  disabled,
  submitText
}: IOrderSummaryProps) => {
  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { currency } = useCurrency()

  if (!quoteDetail || !quoteDetail.line_items) {
    return <Loading></Loading>
  }

  const { total_price_without_tax, delivery_price, total_price, tax_value } =
    quoteDetail

  const sections = [
    {
      title: "Delivery Address",
      condition: orderData?.delivery_address,
      text: `${orderData?.delivery_address?.city}, ${orderData?.delivery_address?.zipCode}`,
      icon: DeliveryAddressIcon
    },
    {
      title: "Shipping Method",
      condition: orderData?.shipping_method,
      text: orderData?.shipping_carrier || orderData?.shipping_method,
      icon: shippingMethodIcon
    },
    {
      title: "Billing Address",
      condition:
        orderData?.billing && paymentMethod !== EPaymentMethod.CREDIT_CARD,
      text: `${orderData?.billing?.city}, ${orderData?.billing?.zipCode}`,
      icon: billingAddressSectionIcon
    }
  ]

  const transferText = (text: string): string => {
    const formattedTextMap: Record<string, string> = {
      [EShippingMethod.LOCAL_PICKUP]: "PICK UP",
      ups: "UPS"
    }

    return (
      formattedTextMap[text] || text.charAt(0).toUpperCase() + text.slice(1)
    )
  }

  return (
    <div className="order-summary-container">
      <div className="order-summary-title">
        <h3>Order summary</h3>
      </div>
      <div className="order-summary-content">
        {quoteDetail?.line_items.map((item) => (
          <div key={item.id}>
            <div className="order-summary-item">
              <div className="order-summary-item-info">
                <img
                  src={item.product.thumb_120x120}
                  alt="order item"
                  width={21}
                  height={16}
                />
                <p>{item.product.title}</p>
              </div>
              <p>x{item.amount}</p>
            </div>
          </div>
        ))}
        {(orderData?.delivery_address ||
          orderData?.shipping_method ||
          orderData?.billing) && (
          <div className="order-summary-section">
            {sections.map(
              (section, index) =>
                section.condition && (
                  <div className="order-summary-section-item" key={index}>
                    <img
                      src={section.icon}
                      height={24}
                      width={24}
                      alt={`${section.title} icon`}
                    />
                    <div className="order-summary-section-item-text">
                      <p>{section.title}</p>
                      <p>{transferText(section.text)}</p>
                    </div>
                  </div>
                )
            )}
          </div>
        )}

        <div className="order-summary-price">
          <div className="order-summary-price-section">
            <p>Subtotal</p>
            <p>
              {currency}
              {priceDisplay(Number(total_price_without_tax))}
            </p>
          </div>
          <div className="order-summary-price-section">
            <p>Delivery</p>
            {isLoading ? (
              <Loading></Loading>
            ) : (
              <p>
                {currency}
                {priceDisplay(Number(delivery_price))}
              </p>
            )}
          </div>
          <div className="order-summary-price-section">
            <p>Tax</p>
            {isLoading ? (
              <Loading></Loading>
            ) : (
              <p>
                {currency}
                {priceDisplay(tax_value || 0)}
              </p>
            )}
          </div>
        </div>

        <div className="order-summary-total-price">
          <p>Total</p>
          <p>
            {currency}
            {priceDisplay(Number(total_price))}
          </p>
        </div>
        <Button
          customClassName="order-summary-btn"
          customSize={Size.LARGE}
          onClick={handleSubmit}
          disabled={disabled}
        >
          {submitText}
        </Button>
      </div>
    </div>
  )
}

export default OrderSummary
