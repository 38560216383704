import "./ModalManualRequest.scss"
import { attachFileIcon, removeFileIcon } from "@/assets"
import { Button, FormField, Modal, Textarea, TextField } from "@/components"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import { errorStatus } from "@/constants/common.constant"
import { Form, Upload, UploadFile, UploadProps } from "antd"
import { Controller, FieldError, useForm } from "react-hook-form"
import { IManualRequestExistingForm } from "@/interfaces/specification.interface"
import { MANUAL_REQUEST_EXISTING_SCHEMA } from "@/validations/manualRequest.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { useState, useEffect } from "react"
import {
  QuoteResponse,
  useCreateManualRequestMutation,
  useUploadAdditionalFilesMutation
} from "@/services/apiDigifabster/quote"
import { RcFile } from "antd/es/upload"
import { useAppLoading } from "@/hooks/useLoading"
import * as toast from "@/utils/Toast"
import { useNavigate } from "react-router-dom"

interface IModalManualRequestProps {
  openModal: boolean
  closeModal: () => void
  currentQuote?: QuoteResponse
  prefillFile?: UploadFile[]
}

function ModalManualRequest({
  openModal,
  closeModal,
  currentQuote,
  prefillFile
}: IModalManualRequestProps) {
  const [uploadAdditionalFiles, { isLoading: isLoadingUploadAdditionalFiles }] =
    useUploadAdditionalFilesMutation()
  const [
    createManualRequest,
    { isLoading: isLoadingCreateManualRequest, isSuccess }
  ] = useCreateManualRequestMutation()
  useAppLoading([isLoadingUploadAdditionalFiles, isLoadingCreateManualRequest])
  const navigate = useNavigate()
  const [listFiles, setListFiles] = useState<UploadFile[]>([])

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<IManualRequestExistingForm>({
    resolver: yupResolver(MANUAL_REQUEST_EXISTING_SCHEMA)
  })

  useEffect(() => {
    if (prefillFile) {
      setListFiles(prefillFile)
    }
  }, [prefillFile])

  const handleChangeFile: UploadProps["onChange"] = ({ file }) => {
    setListFiles([file])
  }

  const handleRemoveFile = (file: UploadFile) => {
    setListFiles((prev) => prev.filter((item) => item.uid !== file.uid))
  }

  const onSubmit = async (data: IManualRequestExistingForm) => {
    try {
      const { name, phone, email, description } = data
      let payload = {
        order_id: currentQuote?.id || 0,
        arg: {
          fileName: "",
          email: email || "",
          name: name || "",
          phone: phone || "",
          msg: description
        }
      }
      const uploadPayload = new FormData()
      listFiles.forEach((file) => {
        uploadPayload.append(
          "attachment_files",
          file.originFileObj as RcFile,
          file.name
        )
      })
      uploadPayload.append("file_type", "client")

      if (listFiles.length) {
        const { data: uploadAdditionalFilesData } = await uploadAdditionalFiles(
          {
            order_id: currentQuote?.id || 0,
            arg: uploadPayload
          }
        )
        if (!uploadAdditionalFilesData)
          throw new Error("Upload additional files failed")
        payload = {
          ...payload,
          arg: {
            ...payload.arg,
            fileName: uploadAdditionalFilesData?.[0]?.name || ""
          }
        }
      }
      const res = await createManualRequest(payload)
      if (!res.data?.invoice_id || !res.data?.invoice_hash)
        return navigate("/quotes")
      navigate(
        `/quotes/invoices/${res?.data?.invoice_id}/${res?.data?.invoice_hash}`
      )
    } catch (error) {
      const message = error as any
      toast.showError(message.message)
    }
  }

  const handleClose = () => {
    closeModal()
    setListFiles([])
    reset()
  }

  const customItemRender = (file: UploadFile) => {
    return (
      <div key={file.uid} className="file-render">
        <p>{file.name}</p>
        <img
          src={removeFileIcon}
          alt=""
          onClick={() => handleRemoveFile(listFiles[0])}
        />
      </div>
    )
  }
  return (
    <Modal
      className="modal-custom-request"
      title="Request manual check"
      open={openModal}
      onCancel={handleClose}
      footer={false}
    >
      <p className="modal-custom-request-header">
        We aim to respond to all requests the same business day; however,
        complex projects may require up to 24 hours for review. If you have any
        questions, comments, or urgent requests, please call us at
        1-877-329-9131 or email us at sales@forgelabs.com.
      </p>
      <Form className="manual-form-container" onFinish={handleSubmit(onSubmit)}>
        <div className="manual-form-spacing">
          <FormField textLabel="YOUR NAME" errorText={errors.name?.message}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter your name"
                  status={errorStatus(errors.name)}
                />
              )}
            ></Controller>
          </FormField>
          <FormField textLabel="PHONE" errorText={errors.phone?.message}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter your phone number"
                  status={errorStatus(errors.phone)}
                />
              )}
            ></Controller>
          </FormField>
          <FormField textLabel="E-MAIL" errorText={errors.email?.message}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter your email"
                  status={errorStatus(errors.email)}
                />
              )}
            ></Controller>
          </FormField>
          <FormField
            textLabel="PART REQUIREMENTS"
            errorText={errors.description?.message}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Textarea
                  {...field}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  placeholder="Please provide material requirements, quantities and required delivery date"
                  status={errorStatus(errors.description)}
                />
              )}
            ></Controller>
          </FormField>
          <div>
            {listFiles.length > 0 ? (
              <>{customItemRender(listFiles[0])}</>
            ) : (
              <Upload
                accept="*/*"
                onChange={handleChangeFile}
                fileList={listFiles}
                showUploadList={false}
              >
                <Button
                  customSize={Size.LARGE}
                  hierarchy={ButtonHierarchy.LINK}
                >
                  <img src={attachFileIcon} alt="" />
                  Attach file
                </Button>
              </Upload>
            )}
          </div>
          <div className="manual-form-btn">
            <Button
              htmlType="submit"
              customSize={Size.LARGE}
              customClassName="manual-form-btn-next"
            >
              Submit Request
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalManualRequest
