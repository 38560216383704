import { calculateDeliveryDate } from "@/utils/timeFormatHelper"
import "./OrderDetailItem.scss"
import { LineItem } from "@/services/apiDigifabster/quote"
import { calculatePerPartCost } from "@/utils/dataHelper"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
interface IOrderDetailItemProps {
  placedAt: string
  item: LineItem
  isLastItem: boolean
  currency: string
}

function OrderDetailItem({
  item,
  isLastItem,
  currency,
  placedAt
}: IOrderDetailItemProps) {
  const { thumb_120x120, title, size, volume, units } = item.product
  const {
    technology_title,
    material_title,
    config,
    amount,
    total_price,
    unit_price
  } = item

  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)

  return (
    <div className="order-product">
      <div
        className={`order-product-detail ${
          isLastItem ? "render-border-bottom" : ""
        }`}
      >
        <div className="order-product-detail-container">
          <div className="order-product-detail-container-col-1">
            <div className="product-img">
              <img src={thumb_120x120} alt="" />
            </div>
            <div className="product-text-box">
              <p>{title}</p>
              <p>
                {size.x.toFixed(2) || 0} x {size.y.toFixed(2) || 0} x{" "}
                {size.z.toFixed(2) || 0}
              </p>
              <p>
                {volume.toFixed(2) || 0} {units || "mm"}3
              </p>
            </div>
          </div>
          <div className="order-product-detail-container-col-2">
            <p>{technology_title}</p>
            <p>
              {material_title || ""} {config?.color?.value.label && ","}{" "}
              {config?.color?.value.label || ""}
            </p>
            <p>{config.layer_thickness.value || 0} um</p>
            <p>{config.filling.name_for_user || ""}</p>
            {/* <p>Production Note</p> */}
          </div>
          <div className="order-product-detail-container-col-3">
            <p>{amount}</p>
          </div>
          <div className="order-product-detail-container-col-4">
            <p>
              {currency}
              {calculatePerPartCost(
                unit_price,
                quoteDetail?.product_count,
                Number(quoteDetail?.startup_cost),
                amount
              )}
            </p>
            <p>
              {currency}
              {calculatePerPartCost(
                unit_price,
                quoteDetail?.product_count,
                Number(quoteDetail?.startup_cost)
              )}{" "}
              / part
            </p>
          </div>
        </div>
        <div className="order-product-detail-container-time">
          <p>Ready soon as:</p>
          <p>
            {calculateDeliveryDate(
              config.lead_time.days,
              placedAt ? new Date(placedAt) : new Date()
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default OrderDetailItem
