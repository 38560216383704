import React, { useEffect, useState } from "react"
import "./DeliveryAddress.scss"
import {
  Button,
  CountrySelect,
  FormField,
  SelectDropdown,
  TextField
} from "@/components"
import { ButtonHierarchy, Type } from "@/enums/common.enum"
import { IDeliveryForm } from "@/interfaces/delivery.interface"
import { getStatesList } from "@/constants/countries"
import {
  DEFAULT_COUNTRY_ID,
  errorStatus,
  EMTY_STATE
} from "@/constants/common.constant"
import { CountryOptions, IState } from "@/interfaces/common.interface"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { DELIVERY_SCHEMA } from "@/validations/delivery.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { ConfigProvider, Row, Col, Form } from "antd"
import { FieldError, useForm, Controller } from "react-hook-form"
import { useSelector } from "react-redux"
import { ECollapseKey } from "@/enums/checkout.enum"
import _ from "lodash"
interface IDeliveryAddressProps {
  onSubmit: (data: IDeliveryForm) => void
  prefillData?: IDeliveryForm
  isEdit: boolean
  handlePreStep: () => void
  setRemovedKey: React.Dispatch<React.SetStateAction<ECollapseKey | undefined>>
}
const DeliveryAddress: React.FC<IDeliveryAddressProps> = ({
  onSubmit,
  prefillData,
  handlePreStep,
  setRemovedKey
}) => {
  const [countriesList, setCountriesList] = useState<CountryOptions[]>([])
  const [stateList, setStateList] = useState<CountryOptions[]>([])
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const acceptCountries = ["CA", "US"]
  const { name, surname, email, phone_number } = userInfoNew
  const last_delivery_address = userInfoNew?.last_delivery_info
  const defaultDeliveryFormValues: IDeliveryForm = {
    name: prefillData?.name || last_delivery_address?.name || name,
    surname: prefillData?.surname || last_delivery_address?.surname || surname,
    phoneNumber:
      prefillData?.phoneNumber || last_delivery_address?.phone || phone_number,
    email: prefillData?.email || last_delivery_address?.email || email,
    additionalEmail:
      prefillData?.additionalEmail === undefined
        ? last_delivery_address?.additional_email || ""
        : prefillData?.additionalEmail,
    city: prefillData?.city || last_delivery_address?.city,
    state: prefillData?.state || ({} as IState),
    addressLine1:
      prefillData?.addressLine1 || last_delivery_address?.street_address,
    addressLine2:
      prefillData?.addressLine2 === undefined
        ? last_delivery_address?.apartment || ""
        : prefillData?.addressLine2,
    zipCode: prefillData?.zipCode || last_delivery_address?.postcode,
    country: prefillData?.country || {
      id: DEFAULT_COUNTRY_ID,
      name: "Canada",
      iso2: "CA"
    },
    company:
      prefillData?.company === undefined
        ? last_delivery_address?.company_name || ""
        : prefillData?.company
  }

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    formState: { errors, isSubmitted, isDirty, isSubmitting, defaultValues }
  } = useForm<IDeliveryForm>({
    defaultValues: defaultDeliveryFormValues,
    resolver: yupResolver(DELIVERY_SCHEMA)
  })

  useEffect(() => {
    if (prefillData) {
      reset(defaultDeliveryFormValues)
    }
  }, [prefillData])

  const formData = watch()
  useEffect(() => {
    if (!isDirty) {
      const prefillCountry = countriesList.find(
        (item) => item.iso2 === last_delivery_address?.country
      )
      const mapprefillCountry = {
        name: prefillCountry?.title || "Canada",
        id: prefillCountry?.id || DEFAULT_COUNTRY_ID,
        iso2: prefillCountry?.iso2 || "CA"
      }
      setValue(
        "country",
        prefillData?.country ||
          mapprefillCountry || {
            id: DEFAULT_COUNTRY_ID,
            name: "Canada",
            iso2: "CA"
          }
      )
    }
  }, [countriesList, isDirty])

  useEffect(() => {
    if (
      !isSubmitting &&
      (Object.keys(errors).length || !_.isEqual(formData, defaultValues))
    ) {
      setRemovedKey(ECollapseKey.DELIVERY_ADDRESS)
    }
  }, [formData])

  useEffect(() => {
    const getStates = async (countryId: number) => {
      const states = await getStatesList(countryId)
      setStateList(states)
      if (!isDirty) {
        const prefillState = states.find(
          (item) => item.iso2 === last_delivery_address?.state
        )
        const mapprefillState = {
          name: prefillState?.title || "",
          id: prefillState?.id || "",
          iso2: prefillState?.iso2 || ""
        }
        setValue("state", prefillData?.state || mapprefillState || EMTY_STATE)
      }
    }

    const countryId = watch("country").id
    if (countryId) {
      getStates(Number(countryId))
      return
    }
    setStateList([])
  }, [watch("country")])

  const handleChangeState = (
    stateId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = stateList.find((country) => country.id === stateId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || "")
  }

  const handleChangeCountry = (
    countryId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = countriesList.find((country) => country.id === countryId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    setValue("state", {} as IState)
    isSubmitted &&
      setError("state", { type: "required", message: "State is required" })
    onChangeCallback(data || {})
  }

  return (
    <div>
      <>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Open Sans", sans-serif'
            }
          }}
        >
          <Form onFinish={handleSubmit(onSubmit)}>
            <p className="info-box-text-field">Required field *</p>
            <p className="title">CONTACT</p>
            <Row gutter={12} className="spacing-12">
              <Col span={12}>
                <FormField
                  textLabel="First Name *"
                  errorText={errors.name?.message}
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        status={errorStatus(errors.name)}
                        placeholder="Enter name"
                      />
                    )}
                  />
                </FormField>
              </Col>
              <Col span={12}>
                <FormField
                  textLabel="Last Name *"
                  errorText={errors.surname?.message}
                >
                  <Controller
                    name="surname"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        status={errorStatus(errors.surname)}
                        placeholder="Enter name"
                      />
                    )}
                  />
                </FormField>
              </Col>
            </Row>
            <Row gutter={12} className="spacing-12">
              <Col span={12}>
                <FormField
                  textLabel="Phone Number *"
                  errorText={errors.phoneNumber?.message}
                >
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        status={errorStatus(errors.phoneNumber)}
                        placeholder="Enter number"
                      />
                    )}
                  />
                </FormField>
              </Col>
              <Col span={12}>
                <FormField
                  textLabel="Email Address *"
                  errorText={errors.email?.message}
                >
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        status={errorStatus(errors.email)}
                        placeholder="Enter address"
                      />
                    )}
                  />
                </FormField>
              </Col>
            </Row>
            <Row className="spacing-12">
              <Col span={24}>
                <FormField textLabel="Additional emails for order notifications (optional)">
                  <Controller
                    name="additionalEmail"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} placeholder="Add emails" />
                    )}
                  />
                </FormField>
              </Col>
            </Row>
            <p className="title pt-12 delivery-address">DELIVERY ADDRESS</p>
            <Row className="spacing-12">
              <Col span={24}>
                <FormField textLabel="Company (optional)">
                  <Controller
                    name="company"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} placeholder="Enter company name" />
                    )}
                  />
                </FormField>
              </Col>
            </Row>
            <Row gutter={12} className="spacing-12">
              <Col span={12}>
                <FormField
                  textLabel="Address Line 1 *"
                  errorText={errors.addressLine1?.message}
                >
                  <Controller
                    name="addressLine1"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        status={errorStatus(errors.addressLine1)}
                        placeholder="Enter address"
                      />
                    )}
                  />
                </FormField>
              </Col>
              <Col span={12}>
                <FormField
                  textLabel="Address Line 2"
                  errorText={errors.addressLine2?.message}
                >
                  <Controller
                    name="addressLine2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        status={errorStatus(errors.addressLine2)}
                        placeholder="Enter address"
                      />
                    )}
                  />
                </FormField>
              </Col>
            </Row>
            <Row gutter={12} className="spacing-12">
              <Col span={12}>
                <FormField textLabel="City *" errorText={errors.city?.message}>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        status={errorStatus(errors.city)}
                        placeholder="Add city"
                      ></TextField>
                    )}
                  />
                </FormField>
              </Col>
              <Col span={12}>
                <FormField
                  textLabel="State / Province *"
                  errorText={errors.state?.message}
                >
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectDropdown
                        status={errorStatus(errors?.state as FieldError)}
                        width={"100%"}
                        dropdownClassName="dropdown-menu"
                        value={value?.id}
                        searchBar={true}
                        valueChangeToClear={watch("country")?.id}
                        onChange={(stateId) =>
                          handleChangeState(stateId, onChange)
                        }
                        options={stateList}
                        virtual={false}
                        placeholder="Select state / province"
                      />
                    )}
                  />
                </FormField>
              </Col>
            </Row>
            <Row gutter={12} className="spacing-12">
              <Col span={12}>
                <FormField
                  textLabel="Zip Code *"
                  errorText={errors.zipCode?.message}
                >
                  <Controller
                    name="zipCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        status={errorStatus(errors.zipCode)}
                        placeholder="Enter zip code"
                      />
                    )}
                  />
                </FormField>
              </Col>
              <Col span={12}>
                <FormField textLabel="Country *">
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CountrySelect
                        countriesList={countriesList}
                        status={errorStatus(errors?.country as FieldError)}
                        setCountriesList={setCountriesList}
                        flag={`https://flagcdn.com/w20/${value?.iso2?.toLowerCase()}.png`}
                        value={value.id}
                        width={"100%"}
                        dropdownClassName="dropdown-menu"
                        handleChange={(countryId) =>
                          handleChangeCountry(countryId, onChange)
                        }
                        acceptCountries={acceptCountries}
                        placeholder="Select country"
                      />
                    )}
                  />
                </FormField>
              </Col>
            </Row>

            <div className="collapse-footer">
              <Button
                customType={Type.NEUTRAL}
                hierarchy={ButtonHierarchy.OUTLINE}
                onClick={handlePreStep}
              >
                Previous Step
              </Button>
              <Button htmlType="submit" customType={Type.NEUTRAL}>
                Next Step
              </Button>
            </div>
          </Form>
        </ConfigProvider>
      </>
    </div>
  )
}

export default DeliveryAddress
