import { Product } from "@/services/apiDigifabster/quote"
import { useMemo } from "react"
import "./OrderReviewItem.scss"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { ModelResponse } from "@/services/apiDigifabster/model"
import { CustomBadge } from "@/components"
import { BadgeColor, BadgeType, Size } from "@/enums/common.enum"
import { priceDisplay } from "@/utils/functionHelper"
import { useCurrency } from "@/hooks/useCurrency"
import { calculateDeliveryDate } from "@/utils/timeFormatHelper"
import { DrawingIcon } from "@/assets"
import { IQuoteStore } from "@/store/quote"
import { calculatePerPartCost } from "@/utils/dataHelper"

interface IOrderReviewItemProps {
  item: Product
}

function OrderReviewItem({ item }: IOrderReviewItemProps) {
  const {
    config_formatted_json,
    model_title,
    model_id,
    material_display_name,
    config,
    id,
    total_price,
    price_per_part,
    drawing_files,
    accept_drawing,
    count
  } = item

  const { currency } = useCurrency()
  const { currentQuote } = useSelector<RootState, IQuoteStore>((s) => s.quote)

  const currentModel = useSelector<RootState, ModelResponse | undefined>(
    (s) => s.quote.currentModels[model_id]
  )
  const tech = useMemo(
    () => config_formatted_json?.find((item) => item.key === "Technology"),
    [config_formatted_json]
  )

  return (
    <div className="order-checkout-container">
      <div className="order-review-box-test">
        <div className="order-item-box-col-1">
          <div>
            <img
              src={currentModel?.thumb_120x120}
              alt=""
              width={68}
              height={54}
            />
          </div>
          <div className="">
            <p>{currentModel?.title || ""}</p>
            <div className="order-item-box-col-1-size">
              <p>{`${currentModel?.size?.x.toFixed(2) || 0} x 
                  ${currentModel?.size?.y.toFixed(2) || 0}
                 x 
                  ${currentModel?.size?.z.toFixed(2) || 0} ${
                currentModel?.units || ""
              }
                `}</p>
            </div>
            <div className="order-item-box-col-1-volume">
              <p>
                {currentModel?.volume.toFixed(2)}{" "}
                {currentModel?.units ? currentModel?.units + "3" : ""}
              </p>
            </div>

            {accept_drawing && (
              <div className="order-drawings">
                {drawing_files?.map((item: any) => (
                  <div key={item.id} className="order-drawings-item">
                    <img width={12} height={12} src={DrawingIcon} alt="" />
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="order-item-box-col-2">
          <p>{tech?.value}</p>
          <p>
            {material_display_name} ,{config.color.name_for_user || ""}
          </p>
          <p>{config.layer_thickness.name_for_user || ""} </p>
          <p>
            {config.filling.name_for_user ||
              config.filling.value?.toFixed(1) + " percent" ||
              ""}
          </p>
        </div>
        <div className="order-item-box-col-5">
          <p>Visual Inspection</p>
          {config?.post_production?.length > 0
            ? config.post_production.map((item) => (
                <p key={item.title}>
                  {item.title} {item.countable && `(${item.quantity})`}
                </p>
              ))
            : ""}{" "}
        </div>
        <div className="order-item-box-col-3">
          <p>{count}</p>
        </div>
        <div className="order-item-box-col-4">
          <div className="">
            <p>
              {currency}
              {Number(
                calculatePerPartCost(
                  price_per_part,
                  currentQuote?.product_count,
                  Number(currentQuote?.startup_cost),
                  count
                )
              )}
            </p>
            <p>
              {currency}
              {calculatePerPartCost(
                price_per_part,
                currentQuote?.product_count,
                Number(currentQuote?.startup_cost)
              )}
              / part
            </p>
          </div>

          <CustomBadge
            customClassName="order-item-box-col-4-badge"
            content={`Ready soon as:  ${calculateDeliveryDate(
              config?.lead_time?.days,
              new Date()
            )}`}
            color={BadgeColor.SUCCESS}
            type={BadgeType.TONAL}
            size={Size.SMALL}
          />
        </div>
      </div>
      {/* {accept_drawing && (
        <div className="order-drawings">
          {drawing_files?.map((item: any) => (
            <div key={item.id} className="order-drawings-item">
              <p>{item.name}</p>
              <CloseOutlined
                width={12}
                height={12}
                onClick={() => handleDeleteDrawing(item.id)}
              />
            </div>
          ))}
        </div>
      )}
      <div className="order-production-time">
        <p>Ready soon as:</p>
        <p>{calculateDeliveryDate(config.lead_time.days)}</p>
      </div> */}
    </div>
  )
}

export default OrderReviewItem
