import { useEffect, useMemo, useState } from "react"
import { Pagination } from "@/components"
import RootContainer from "@/components/RootContainer/RootContainer"
import "./OrdersPage.scss"
import OrderListTable from "./components/OrderListTable/OrderListTable"
import { useGetListQuoteQuery } from "@/services/apiDigifabster/quote"
import { Order } from "@/interfaces/orderListTable.interface"
import { useAppLoading } from "@/hooks/useLoading"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { useNavigate, useSearchParams } from "react-router-dom"
import { priceDisplay } from "@/utils/functionHelper"
import { useCurrency } from "@/hooks/useCurrency"

export default function OrdersPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPage, setCurrentPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  )
  const [pageSize, setPageSize] = useState<number>(
    Number(searchParams.get("limit")) || 15
  )

  const { data, isError, refetch, isFetching, isLoading } =
    useGetListQuoteQuery({
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
      ordering: "-date",
      status__in: [],
      status__not__in: [
        EOrderStatus.INITIAL,
        EOrderStatus.CREATED,
        EOrderStatus.WAITING_FOR_REVIEW,
        EOrderStatus.FIRM_OFFER_SENT,
        EOrderStatus.CANCEL_BY_ADMIN,
        EOrderStatus.CANCEL_BY_USER
      ]
    })
  useAppLoading([isLoading, isFetching])

  const navigate = useNavigate()
  const { currency } = useCurrency()

  useEffect(() => {
    const loadingOrdersList = async () => {
      setSearchParams({
        page: currentPage.toString(),
        limit: pageSize.toString()
      })
      await refetch()
    }
    loadingOrdersList()
  }, [currentPage, pageSize, refetch])

  const orderData: Order[] = useMemo(() => {
    return (
      data?.results?.map((order) => ({
        no: order.company_order_id,
        orderDate: order.date,
        total: `${currency}${priceDisplay(
          order.total_price - order.startup_cost
        )}`,
        quoteStatus: order.status_display,
        status: order.status,
        id: order.id
      })) || []
    )
  }, [data, currentPage, pageSize])

  const handleSizeChange = (current: number, size: number) => {
    setPageSize(size)
    setCurrentPage(1)
  }

  const handleChange = (page: number) => {
    setCurrentPage(page)
  }

  const onDetail = (id: number) => {
    navigate(`/orders/${id}?page=${currentPage}&limit=${pageSize}`)
  }

  if (!data || isFetching) return <></>

  return (
    <RootContainer headerText={`All Orders (${data?.count || 0})`}>
      <div className="order">
        <div className="order-container">
          <OrderListTable data={orderData} onDetail={onDetail} />
          <div className="order-container-pagination">
            {!!data?.count && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={data?.count || 0}
                onChange={handleChange}
                onShowSizeChange={handleSizeChange}
              />
            )}
          </div>
        </div>
      </div>
    </RootContainer>
  )
}
