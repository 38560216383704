import { REGEX_ONLY_NUMBER, REQUIRED_ERROR } from "@/constants/common.constant"
import { IState } from "@/interfaces/common.interface"
import { array, mixed, object, string } from "yup"

export const CREDIT_SCHEMA = object({
  cardNumber: string()
    .required(REQUIRED_ERROR("Card number"))
    .matches(REGEX_ONLY_NUMBER, "Invalid format"),
  expirationDate: string().required(REQUIRED_ERROR("Expiration Date")),
  securityCode: string().required(REQUIRED_ERROR("Security Code")),
  country: mixed<IState>().required(REQUIRED_ERROR("Country")),
  zipCode: string()
    .required(REQUIRED_ERROR("Zip Code"))
    .matches(REGEX_ONLY_NUMBER, "Invalid Format")
})

export const PURCHASE_SCHEMA = object({
  poNumber: string()
})

export const PO_SCHEMA = object({
  poNumber: string().required(REQUIRED_ERROR("PO Number")),
  poFile: array()
    .min(1, "PO file is required")
    .required(REQUIRED_ERROR("PO File"))
})
