import { useCheckWallThicknessMutation } from "@/services/apiDigifabster/model"
import { Product } from "@/services/apiDigifabster/quote"
import { chunkArray } from "@/utils/functionHelper"

export const useCheckWallThickness = () => {
  const [checkWallThickness, { isError: isWallThickNessError }] =
    useCheckWallThicknessMutation()

  const callCheckWallThickness = (materialId: number, modelIds: number[]) => {
    const chunkedModels = chunkArray(modelIds, 10)
    return Promise.all(
      chunkedModels.map((e) =>
        checkWallThickness({
          materials_ids: [materialId],
          models_ids: e
        })
      )
    )
  }

  const handleCheckWallThickness = async (product: Product[]) => {
    const sameMaterialId = product.reduce<Record<number, number[]>>(
      (pre, cur) => ({
        ...pre,
        [cur.material_id]: pre[cur.material_id]
          ? [...pre[cur.material_id], cur.model_id]
          : [cur.model_id]
      }),
      {}
    )

    await Promise.all(
      Object.keys(sameMaterialId).map((materialId: any) =>
        callCheckWallThickness(materialId, sameMaterialId[materialId])
      )
    )
  }

  return { handleCheckWallThickness, isWallThickNessError }
}
