import {
  Button,
  Drawer,
  FormField,
  RadioButton,
  SelectDropdown,
  Textarea,
  TextField,
  Toast,
  UploadFiles
} from "@/components"
import "./ManualRequestDrawer.scss"
import {
  SERVICE_LABEL,
  IS_HAVE_CAD_FILE,
  BUDGET,
  TIMELINE,
  BUDGET_OPTIONS,
  IS_HAVE_CAD_FILE_OPTIONS,
  SERVICES,
  TIMELINE_OPTIONS
} from "@/constants/createQuote.constrant"
import { useAppLoading } from "@/hooks/useLoading"
import { IFormManualRequest } from "@/interfaces/createQuote.interface"
import {
  useCreateUploadJobMutation,
  useCreateOrderMutation,
  useUploadAdditionalFilesMutation,
  useCreateManualRequestMutation
} from "@/services/apiDigifabster/quote"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { MANUAL_REQUEST_SCHEMA } from "@/validations/manualRequest.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { UploadFile, RadioChangeEvent, Radio } from "antd"
import { RcFile, UploadProps } from "antd/es/upload"
import { useState, useEffect, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { EHaveCADFile } from "@/enums/createQuote.enum"
import { errorStatus } from "@/constants/common.constant"
import { Size } from "@/enums/common.enum"
import { handleSelectChange } from "@/utils/functionHelper"
import * as toast from "@/utils/Toast"

const MANUAL_REQUEST_TITLE = {
  title: "Manual request",
  subtitle: "Request Engineering Review"
}
interface IManualRequestDrawerProps {
  open: boolean
  onClose?: ((e: React.MouseEvent | React.KeyboardEvent) => void) | undefined
}
const ManualRequestDrawer: React.FC<IManualRequestDrawerProps> = ({
  open,
  onClose
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitted }
  } = useForm<IFormManualRequest>({
    resolver: yupResolver(MANUAL_REQUEST_SCHEMA)
  })
  const [methodValue, setMethodValue] = useState()
  const [isHaveCADFile, setIsHaveCADFile] = useState<string>()
  const [budget, setBudget] = useState<string>()
  const [listFiles, setListFiles] = useState<UploadFile[]>([])
  const [timeline, setTimeline] = useState<string>()
  const navigate = useNavigate()
  const [uploadJob, { isLoading: isLoadingUploadJob }] =
    useCreateUploadJobMutation()
  const [createOrder, { isLoading: isLoadingCreateOrder, data }] =
    useCreateOrderMutation()
  const [uploadAdditionalFiles, { isLoading: isLoadingUploadAdditionalFiles }] =
    useUploadAdditionalFilesMutation()
  const [
    createManualRequest,
    { isLoading: isLoadingCreateManualRequest, isSuccess }
  ] = useCreateManualRequestMutation()

  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)

  useAppLoading([
    isLoadingUploadJob,
    isLoadingCreateOrder,
    isLoadingUploadAdditionalFiles,
    isLoadingCreateManualRequest
  ])

  const onChangeMethod = (
    e: RadioChangeEvent,
    onChange: (...event: any[]) => void,
    setValue: (value: React.SetStateAction<any>) => void
  ) => {
    onChange(e.target.value)
    setValue(e.target.value)
  }

  const onSubmit = async (data: IFormManualRequest) => {
    try {
      if (isRequiredFile && !listFiles.length) return

      const { data: uploadJobData } = await uploadJob()
      if (!uploadJobData) throw new Error("Create order failed")

      const { data: order } = await createOrder({
        uploadJobId: uploadJobData.uj
      })
      if (!order) throw new Error("Create order failed")

      const { name, email, phone_number } = userInfoNew
      let payload = {
        order_id: order.id,
        arg: {
          fileName: "",
          email: email || "",
          name: name || "",
          phone: phone_number || "",
          msg: `<ol style='padding-left: 0;margin: 0;'><li>What services are you interested in? - ${
            SERVICE_LABEL[data.service as keyof typeof SERVICE_LABEL] || ""
          } </li> <li>Tell us about your project- ${
            data.projectOverview
          } </li> <li>Do you have a CAD file? - ${
            IS_HAVE_CAD_FILE[
              data.isHaveCADFile as keyof typeof IS_HAVE_CAD_FILE
            ] || "No"
          } </li> <li>What is your budget?- ${
            BUDGET[data.budget as keyof typeof BUDGET] || ""
          } </o> <li>What is your timeline? - ${
            TIMELINE[data.timeline as keyof typeof TIMELINE] || ""
          } </li> <li>How many units do you need? - ${
            data.unit || ""
          }</li> </ol>`
        }
      }
      const uploadPayload = new FormData()
      listFiles.forEach((file) => {
        uploadPayload.append("attachment_files", file as RcFile, file.name)
      })
      uploadPayload.append("file_type", "client")

      if (listFiles.length) {
        const { data: uploadAdditionalFilesData } = await uploadAdditionalFiles(
          {
            order_id: order.id,
            arg: uploadPayload
          }
        )
        if (!uploadAdditionalFilesData)
          throw new Error("Upload additional files failed")
        payload = {
          ...payload,
          arg: {
            ...payload.arg,
            fileName: uploadAdditionalFilesData?.[0]?.name || ""
          }
        }
      }
      const { data: response, error } = await createManualRequest(payload)
      if (!response || error) throw new Error("Create manual request failed")
      if (!response?.invoice_id || !response?.invoice_hash)
        return navigate(`/quotes`)
      navigate(
        `/quotes/invoices/${response?.invoice_id}/${response?.invoice_hash}`
      )
    } catch (error) {
      const message = error as any
      toast.showError(message.message)
    }
  }

  const handleClose = (e: React.MouseEvent | React.KeyboardEvent) => {
    onClose && onClose(e)
    resetForm()
  }

  const resetForm = () => {
    setIsHaveCADFile(undefined)
    setTimeline(undefined)
    setBudget(undefined)
    setMethodValue(undefined)
    setListFiles([])
    reset()
  }
  const isRequiredFile = useMemo(
    () =>
      isSubmitted && isHaveCADFile === EHaveCADFile.YES && !listFiles.length,
    [listFiles, isHaveCADFile, isSubmitted]
  )

  const handleChangeFile: UploadProps["onChange"] = ({ file }) => {
    setListFiles([file])
  }

  const handleRemoveFile = (file: UploadFile) => {
    setListFiles((prev) => prev.filter((item) => item.uid !== file.uid))
  }
  return (
    <Drawer
      width={420}
      onClose={handleClose}
      open={open}
      customtitle={MANUAL_REQUEST_TITLE}
    >
      <div className="manual-request-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="manual-request-form-section">
            <FormField
              clasName="manual-request-form-label"
              textLabel="1. What services are you interested in?"
              errorText={errors?.service?.message}
              required
            >
              <Controller
                name="service"
                control={control}
                render={({ field: { onChange } }) => (
                  <Radio.Group
                    onChange={(e) =>
                      onChangeMethod(e, onChange, setMethodValue)
                    }
                    value={methodValue}
                  >
                    <div className="manual-request-form-label-options">
                      {SERVICES.map((method, index) => (
                        <RadioButton value={method.value} key={index}>
                          <p className="">{method.label}</p>
                        </RadioButton>
                      ))}
                    </div>
                  </Radio.Group>
                )}
              />
            </FormField>

            <FormField
              clasName="manual-request-form-label"
              textLabel="2. Tell us about your project"
              errorText={errors?.projectOverview?.message}
              required
            >
              <Controller
                name="projectOverview"
                control={control}
                render={({ field }) => (
                  <Textarea
                    maxLength={500}
                    showCount
                    status={errorStatus(errors?.projectOverview)}
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    placeholder="Additional notes"
                    {...field}
                  />
                )}
              />
            </FormField>

            <FormField
              clasName="manual-request-form-label"
              textLabel="3. Do you have a CAD file?"
              errorText={errors?.isHaveCADFile?.message}
              required
            >
              <Controller
                name="isHaveCADFile"
                control={control}
                render={({ field: { onChange } }) => (
                  <Radio.Group
                    className="manual-request-form-select"
                    onChange={(e) =>
                      onChangeMethod(e, onChange, setIsHaveCADFile)
                    }
                    value={isHaveCADFile}
                  >
                    {IS_HAVE_CAD_FILE_OPTIONS.map((option, index) => (
                      <div key={index}>
                        <RadioButton value={option.id}>
                          {option.title}
                        </RadioButton>
                      </div>
                    ))}
                  </Radio.Group>
                )}
              />
            </FormField>

            <FormField
              clasName="manual-request-form-label"
              textLabel="4. What is your budget?"
              errorText={errors?.budget?.message}
              required
            >
              <Controller
                name="budget"
                control={control}
                render={({ field: { onChange } }) => (
                  <SelectDropdown
                    width={"100%"}
                    placeholder="Please select"
                    defaultValue={budget}
                    status={errorStatus(errors?.budget)}
                    onChange={(stateId) =>
                      handleSelectChange(stateId, setBudget, onChange)
                    }
                    options={BUDGET_OPTIONS}
                    virtual={false}
                  />
                )}
              />
            </FormField>

            <FormField
              clasName="manual-request-form-label"
              textLabel="5. What is your timeline?"
              errorText={errors?.timeline?.message}
              required
            >
              <Controller
                name="timeline"
                control={control}
                render={({ field: { onChange } }) => (
                  <SelectDropdown
                    width={"100%"}
                    placeholder="Please select"
                    defaultValue={timeline}
                    status={errorStatus(errors?.timeline)}
                    onChange={(stateId) =>
                      handleSelectChange(stateId, setTimeline, onChange)
                    }
                    options={TIMELINE_OPTIONS}
                    virtual={false}
                  />
                )}
              />
            </FormField>

            <FormField
              clasName="manual-request-form-label"
              textLabel="6. How many units do you need?"
              errorText={errors?.unit?.message}
              required
            >
              <Controller
                name="unit"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    placeholder="Input units number"
                    {...field}
                    status={errorStatus(errors?.unit)}
                  />
                )}
              />
            </FormField>

            <FormField
              clasName="manual-request-form-label"
              errorText={isRequiredFile ? "This field is required" : ""}
              required={isRequiredFile}
            >
              <UploadFiles
                listFiles={listFiles}
                onUploadFiles={handleChangeFile}
                onRemove={handleRemoveFile}
              />
            </FormField>
          </div>
          <div className="manual-request-form-action">
            <Button
              htmlType="submit"
              customSize={Size.LARGE}
              customClassName="manual-request-form-action-btn"
            >
              Submit Manual Request
            </Button>
          </div>
        </form>{" "}
      </div>
      <div className="manual-request-hint-text">
        We aim to respond to requests within 1 business day.
        <br></br>
        <br></br>
        If you have an urgent project, please call our head office at&nbsp;
        <span>1-877-329-9131</span>
      </div>
    </Drawer>
  )
}
export default ManualRequestDrawer
