import { Form } from "antd"
import "./QuoteSummary.scss"
import { Button } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { DownloadIcon } from "@/assets"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { priceDisplay } from "@/utils/functionHelper"
import { useMemo } from "react"
import { useGetPaymentLinkMutation } from "@/services/apiDigifabster/order"
import * as toast from "@/utils/Toast"
import { DIGIFABSTER_INVOICE_URL } from "@/constants/common.constant"
import { useNavigate, useParams } from "react-router-dom"
import { useAppLoading } from "@/hooks/useLoading"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { useCurrency } from "@/hooks/useCurrency"
import { safeConcatUrl } from "@/utils/stringHelper"

enum EStatusPayment {
  QUOTE = "quote",
  PO_PROVIDE = "po_provide",
  READY = "ready",
  PAID = "paid",
  QUOTE_SENT = "firm_offer_sent",
  PO_PAYMNET_DUE = "po_payment_due"
}
const statusPayment = {
  [EStatusPayment.QUOTE]: {
    title: "Preliminary Quote",
    classname: "invoice-summary-box-header-status-quote"
  },
  [EStatusPayment.PO_PROVIDE]: {
    title: "PO provided",
    classname: ""
  },
  [EStatusPayment.READY]: {
    title: "Ready for payment",
    classname: ""
  },
  [EStatusPayment.QUOTE_SENT]: {
    title: "Quote Sent",
    classname: ""
  },
  [EStatusPayment.PO_PAYMNET_DUE]: {
    title: "Payment due",
    classname: ""
  },
  [EStatusPayment.PAID]: {
    title: "Payment succeeded",
    classname: "invoice-summary-box-header-status-paid"
  }
}

const getFileName = (urlString: string) => {
  if (urlString && urlString.startsWith("http")) {
    try {
      const url = new URL(urlString)
      return url.pathname.split("/").pop()
    } catch (error) {
      return ""
    }
  } else {
    return
  }
}

function QuoteSummary() {
  const { invoiceDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { settings } = useSelector((state: RootState) => state.user)
  const [getPaymentLink, { isLoading: isLoadingGetPaymentLink }] =
    useGetPaymentLinkMutation()
  const order = invoiceDetail?.order
  const isPaid = invoiceDetail?.is_paid
  const { currency } = useCurrency()
  const navigate = useNavigate()

  useAppLoading([isLoadingGetPaymentLink])

  const statusPaid = useMemo(() => {
    if (!isPaid) {
      switch (order?.status) {
        case EOrderStatus.WAITING_FOR_REVIEW:
          return EStatusPayment.QUOTE
        case EOrderStatus.FIRM_OFFER_SENT:
          return EStatusPayment.QUOTE_SENT
        case EOrderStatus.PO_PROVIDED:
          return EStatusPayment.PO_PROVIDE
        case EOrderStatus.PO_PAYMNET_DUE:
          return EStatusPayment.PO_PAYMNET_DUE
        default:
          return EStatusPayment.READY
      }
    }
    return EStatusPayment.PAID
  }, [invoiceDetail, order?.status])

  const handlePlaceOrder = async () => {
    const { data, error } = await getPaymentLink({ orderId: order.id || 0 })
    const errorMsg = (error as any)?.data?.message || ""
    if (error || !data) return toast.showError(errorMsg)
    window.location.href = data.payUrl
  }

  return (
    <Form>
      <div className="quote-summary">
        <div className="quote-summary-box">
          <div className="quote-summary-box-header">
            {/* <div
              className={`quote-summary-box-header-status ${statusPayment?.[statusPaid].classname}`}
            >
              <p>{statusPayment?.[statusPaid].title}</p>
            </div> */}
            <p className="quote-summary-box-header-title">Quote Total</p>
          </div>
          <div className="quote-summary-box-content">
            <div className="quote-summary-box-content-price">
              <p>Subtotal</p>
              <p>
                {currency}
                {priceDisplay(invoiceDetail?.order?.models_price)}
              </p>
            </div>
            <div className="quote-summary-box-content-price">
              <p>Delivery</p>
              <p>
                {currency}
                {priceDisplay(invoiceDetail?.order?.delivery_price)}
              </p>
            </div>
            <div className="quote-summary-box-content-price">
              <p>Tax</p>
              <p>
                {currency}
                {priceDisplay(invoiceDetail?.order?.tax_value)}
              </p>
            </div>
          </div>
          <div className="quote-summary-box-total">
            <div className="quote-summary-box-total-price">
              <p>Total</p>
              <p>
                {currency}
                {priceDisplay(invoiceDetail?.order?.total_price)}
              </p>
            </div>
          </div>
          <div className="quote-summary-box-btn">
            {!isPaid &&
              ![
                EOrderStatus.WAITING_FOR_REVIEW,
                EOrderStatus.PO_PROVIDED,
                EOrderStatus.PO_PAYMNET_DUE,
                EOrderStatus.CANCEL_BY_ADMIN,
                EOrderStatus.CANCEL_BY_USER
              ].includes((order?.status as EOrderStatus) || "") && (
                <>
                  <Button
                    customClassName="btn-place-order"
                    customSize={Size.LARGE}
                    onClick={() =>
                      navigate(`checkout/${invoiceDetail?.order?.id}`)
                    }
                    disabled={invoiceDetail?.order?.line_items?.length === 0}
                  >
                    Checkout
                  </Button>
                </>
              )}
          </div>
        </div>
        <div className="quote-summary-footer">
          <Button
            customType={Type.NEUTRAL}
            hierarchy={ButtonHierarchy.OUTLINE}
            customSize={Size.LARGE}
            customClassName="invoice-btn"
            onClick={() => {
              window.open(
                invoiceDetail?.pdf_url.replace(
                  DIGIFABSTER_INVOICE_URL,
                  safeConcatUrl(
                    String(process.env.REACT_APP_BACKEND_ENDPOINT),
                    "invoice"
                  ) || ""
                ) || "",
                "_blank"
              )
            }}
          >
            <img src={DownloadIcon} alt="" />
            Download Quote
          </Button>
          <p>
            Have Questions?&nbsp;
            <a href={`mailto:${settings.support_email}`}>Ask for help</a>
          </p>
        </div>
      </div>
    </Form>
  )
}

export default QuoteSummary
