import { RootContainer } from "@/components"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import "./OrderDetailPage.scss"
import { orderSuccessIcon } from "@/assets"
import OrderDetailItem from "./components/OrderDetailItem/OrderDetailItem"
import {
  useGetAdditionalFileQuery,
  useLazyGetQuoteDetailQuery
} from "@/services/apiDigifabster/quote"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { format } from "date-fns"
import { useAppLoading } from "@/hooks/useLoading"
import { EOrderStatus } from "@/enums/quotesList.enum"
import OrderDetailSummary from "./components/OrderDetailSummary/OrderDetailSummary"
import { useCurrency } from "@/hooks/useCurrency"
import { priceDisplay } from "@/utils/functionHelper"

function OrderDetailPage() {
  const navigate = useNavigate()
  const { quoteId } = useParams()
  const [getQuoteDetail, { data, isFetching, isError }] =
    useLazyGetQuoteDetailQuery()
  const { data: fileData, isFetching: isFetchingFile } =
    useGetAdditionalFileQuery({ quoteId: quoteId ?? "" })
  const { pathname, search } = useLocation()

  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { currency } = useCurrency()

  useAppLoading([isFetching, isFetchingFile])

  const trackingNumber = quoteDetail?.tracking_number || "-"

  const deliveryAddress =
    quoteDetail?.delivery_address?.[quoteDetail.delivery_address.length - 1]

  const orderDate = quoteDetail?.date
    ? format(new Date(quoteDetail.date), "MM.dd.yyyy")
    : ""

  const notes = quoteDetail?.notes || ""

  const status = quoteDetail?.status

  const companyName =
    deliveryAddress?.company_name && deliveryAddress?.company_name !== "blank"
      ? deliveryAddress?.company_name
      : ""

  const street = deliveryAddress?.street_address || ""
  const city = deliveryAddress?.city || ""
  const state = deliveryAddress?.state || ""
  const zipcode = deliveryAddress?.postcode || ""
  const country = deliveryAddress?.country || ""

  const deliveryTitle =
    quoteDetail?.delivery_title || quoteDetail?.delivery_type_title

  const address = [street, companyName, city, state, zipcode, country]
    .filter(Boolean)
    .join(", ")

  useEffect(() => {
    if (quoteId) {
      getQuoteDetail({ quoteId })
    }
  }, [quoteId])

  useEffect(() => {
    if (isError) {
      return navigate(`/not-found`)
    }
  }, [isError])

  const getColorsStatus: Partial<Record<EOrderStatus, string>> = {
    [EOrderStatus.PLACED]: "status-text-success",
    [EOrderStatus.FIRM_OFFER_SENT]: "status-text-warning",
    [EOrderStatus.WAITING_FOR_REVIEW]: "status-text-warning"
  }

  const defaultColorStatus = "status-text-default"

  const getColorStatus =
    getColorsStatus[quoteDetail?.status as EOrderStatus] ?? defaultColorStatus

  const handleBack = () => {
    // if (pathname.includes("quotes")) return navigate("/quotes")
    if (pathname.includes("orders")) {
      const params = new URLSearchParams(search)
      const page = params.get("page")
      const limit = params.get("limit")

      return navigate(`/orders?page=${page}&limit=${limit}`)
    }
    if (pathname.includes("checkout")) return navigate("/new-quote")
  }

  if (!data || isFetching || isFetchingFile) return <></>

  const total_price_without_cost = Number(quoteDetail?.total_price)

  return (
    <RootContainer
      onBack={() => handleBack()}
      headerText="Details"
      subHeaderText={``}
    >
      <div className="order-detail-container">
        <div className="order-detail-left">
          <div className="order-detail-box">
            <div className="order-detail-box-header">
              <div className="img-box">
                <img src={orderSuccessIcon} alt="" />
              </div>
              <div className="text-box">
                {quoteDetail?.status === EOrderStatus.WAITING_FOR_REVIEW ? (
                  <>
                    <p>Your Manual Request is Under Review</p>
                  </>
                ) : (
                  <>
                    <p>
                      Your order Number{" "}
                      <span>#{quoteDetail?.company_order_id}</span> has been
                      successfully placed.
                    </p>
                  </>
                )}

                <p>
                  We are currently reviewing your order and will send you a
                  confirmation email shortly.
                </p>
              </div>
            </div>
            <div className="order-detail-info">
              <p className="order-detail-info-title">ORDER DETAILS</p>
              <div className="order-detail-info-column">
                <div className="column-1">
                  <p className="column-title">Order date</p>
                  <p>{orderDate}</p>
                </div>
                <div className="column-2">
                  <p className="column-title">Total</p>
                  <p>
                    {currency}
                    {priceDisplay(total_price_without_cost)}
                  </p>
                </div>
                <div className="column-3">
                  <p className="column-title">Tracking number</p>
                  <p>{trackingNumber}</p>
                </div>
                <div className="column-4">
                  <p className="column-title">Status</p>
                  <p className={getColorStatus}>
                    {quoteDetail?.status_display}
                  </p>
                </div>
              </div>
            </div>
            <div className="order-detail-product">
              {quoteDetail?.line_items?.map((product, index) => (
                <OrderDetailItem
                  item={product}
                  currency={currency}
                  key={product.id}
                  isLastItem={index === quoteDetail?.line_items.length - 1}
                  placedAt={quoteDetail.placed_at}
                ></OrderDetailItem>
              ))}
            </div>

            <>
              <div className="order-detail-delivery">
                <p className="order-detail-delivery-title">DELIVERY</p>
                <div className="order-detail-delivery-text">
                  <div className="order-detail-delivery-text-type">
                    <p>Type</p>
                    <p>{deliveryTitle}</p>
                  </div>

                  {quoteDetail?.delivery_address?.length !== 0 ? (
                    <div className="order-detail-delivery-text-address">
                      <p>Address</p>
                      <p>{address}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {notes ? (
                <div className="order-detail-delivery">
                  <div className="order-detail-delivery-text">
                    <div className="order-detail-delivery-text-notes">
                      <p>Notes</p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${notes}`
                        }}
                      ></div>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {!!fileData?.length && (
                <div className="addtional-files">
                  {fileData?.map((item: any) => (
                    <div key={item.id} className="addtional-files-item">
                      <p>{item.name}</p>
                    </div>
                  ))}
                </div>
              )}
            </>
          </div>
        </div>
        <div className="order-detail-right">
          <OrderDetailSummary></OrderDetailSummary>
        </div>
      </div>
    </RootContainer>
  )
}

export default OrderDetailPage
